
import { Steps } from 'antd';
import React from 'react';

import { UserRoleActionFragment } from '../../../../graphql/hasura/generated';

import { SendInvitationWithFormik } from './SendInvitationWithFormik';

interface ISendVendorPassInvitationFormProps {
  userRole: UserRoleActionFragment;
}

export const SendVendorPassInvitationForm: React.FC<ISendVendorPassInvitationFormProps> = (props) => {
  const { userRole } = props;
  const { expiresAt, schedule } = userRole;

  const steps = (
    <Steps direction='vertical' size='small' className='send-invitation-steps'>
      {expiresAt && (
        <Steps.Step
          status='finish'
          title='Duration of access'
        />
      )}
      {schedule && (
        <Steps.Step
          status='finish'
          title='Access schedule'
        />
      )}
      <Steps.Step
        status='finish'
        title='Passcode used to verify access to the property'
      />
    </Steps>
  );

  return <SendInvitationWithFormik userRole={userRole} steps={steps} />;
};
