import { notification } from 'antd';

import {
  generateVendorPasscodeDocument,
  generateVendorPasscodeMutation,
  generateVendorPasscodeMutationVariables,
  VendorPasscodeTableFragment
} from '../../graphql/api/generated';
import { PermissionKey_enum } from '../../graphql/hasura/generated';

import model from './model';

export const regenerateAction = model.createAction<VendorPasscodeTableFragment>({
  label: () => 'Regenerate',
  enabledByModel: ({ hasPermission }) => hasPermission(PermissionKey_enum.VendorPasscode_Regenerate),
  confirmation: {
    title: () => 'Regenerate Random Code',
    content: () => (
      'Are you sure you want to regenerate a random code? ' +
      'This action cannot be undone.'
    ),
  },
  executes: async (vendorPasscode, { apiClient, resetStores }) => {
    const { assignedToUser } = vendorPasscode;

    await apiClient.mutate<
      generateVendorPasscodeMutation,
      generateVendorPasscodeMutationVariables
    >({
      mutation: generateVendorPasscodeDocument,
      variables: { assignedToUserId: assignedToUser.userId },
    });

    resetStores();

    notification.success({
      message: 'Success',
      description: 'A new code was successfully generated!',
    });
  },
});
