import { Form } from '@ant-design/compatible';
import { Col, DatePicker, Row, Typography } from 'antd';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';

import { convertSecondsToLargestUnit } from '../../../components/DurationField/helpers';
import { TimetableField } from '../../../components/TimetableField';
import { PermissionKey_enum, PermissionScope_enum } from '../../../graphql/hasura/generated';

import { ISharedFormValues } from './formik';
import { getRoleRequirements } from './getRoleRequirements';

type DurationOfAccessSectionProps = FormikProps<ISharedFormValues> & {
  scheduleAllowed?: boolean;
};

export const DurationOfAccessSection = (props: DurationOfAccessSectionProps) => {
  const { setFieldValue, values, errors: formikErrors, isSubmitting } = props;
  const { scopedIds, role, activatesAt, expiresAt } = values;

  const { expirationDateRequired, maxAccessDurationSeconds } = getRoleRequirements(role);

  if (!scopedIds.length) {
    return null;
  }

  const rolePermissions = role?.rolePermissions || [];

  let scheduleAllowed = props.scheduleAllowed;

  if (scheduleAllowed !== false) {
    scheduleAllowed = (
      values.permissionScope === PermissionScope_enum.PROPERTY ||
      values.permissionScope === PermissionScope_enum.UNIT ||
      values.permissionScope === PermissionScope_enum.ACCESS_POINT
    ) && (
      rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.AccessPoint_Unlock) ||
      rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.SmartLock_Unlock)
    );
  }

  return (
    <section style={{ marginBottom: 20 }}>
      <Typography.Title level={4}>
        Duration of access
      </Typography.Title>

      <Typography.Paragraph>
        Please select the dates the user's access should be activated and expired (if necessary).
      </Typography.Paragraph>

      <Row justify='space-between' gutter={.5}>
        <Col span={11}>
          <Form.Item
            required
            validateStatus={formikErrors.activatesAt ? 'error' : undefined}
            help={formikErrors.activatesAt}
            label='Activation Date'
            style={{ width: '100%' }}
            className='label-align-top'
          >
            <DatePicker
              format='MMM D, YYYY h:mma'
              showTime={{
                use12Hours: true,
                secondStep: 60,
              }}
              disabledDate={d => (
                d?.isBefore(moment(new Date()).startOf('day')) || false
              )}
              allowClear={false}
              placeholder='Activation Date'
              style={{ width: '100%' }}
              value={!!activatesAt ? moment(activatesAt) : undefined}
              disabled={isSubmitting}
              onChange={(nextActivatesAt) => {
                setFieldValue('activatesAt', nextActivatesAt?.toDate());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            required={expirationDateRequired}
            validateStatus={formikErrors.expiresAt ? 'error' : undefined}
            help={formikErrors.expiresAt}
            label={`Expiration Date${expirationDateRequired ? '' : ' (Optional)'}`}
            className='label-align-top'
          >
            <DatePicker
              format='MMM D, YYYY h:mma'
              showTime={{
                use12Hours: true,
                secondStep: 60,
              }}
              disabledDate={d => (
                d?.isBefore(moment(activatesAt || new Date()).startOf('day')) || false
              )}
              placeholder='Expiration Date'
              style={{ width: '100%' }}
              value={!!expiresAt && moment(expiresAt) || undefined}
              disabled={isSubmitting}
              onChange={(nextExpiresAt) => {
                setFieldValue('expiresAt', nextExpiresAt?.toDate());
              }}
            />
            {typeof maxAccessDurationSeconds === 'number' && (
              <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.45)' }}>
                Maximum Duration:&nbsp;
                {convertSecondsToLargestUnit(maxAccessDurationSeconds).displayedValue}
              </span>
            )}
          </Form.Item>
        </Col>
      </Row>
      {scheduleAllowed && (
        <>
          <Typography.Paragraph>
            Optionally restrict access with schedules.
          </Typography.Paragraph>

          {rolePermissions.some(r => r.permission.key === PermissionKey_enum.Session_AdminPanel) && (
            <Typography.Paragraph>
              (Only applies to unlocking property access points and/or unit smart locks.
              Does not apply to Admin Panel access.)
            </Typography.Paragraph>
          )}

          <Row justify='space-between' gutter={.5}>
            <Col span={24}>
              <TimetableField
                fieldName='schedule'
                values={values}
                onChange={(value) => {
                  setFieldValue('schedule', value, true);
                }}
                modelName='UserRole'
                touched={props.touched}
                errors={formikErrors}
              />
            </Col>
          </Row>
        </>
      )}
    </section>
  );
};
