import { Form } from '@ant-design/compatible';
import { Alert, Button, Divider, Radio, Typography } from 'antd';
import { withFormik } from 'formik';
import React from 'react';

import { PhoneNumberField } from '../../../../components/PhoneNumberField';
import { MessageMethod } from '../../../../graphql/api/generated';
import { FormItem, TextField } from '../../../../pages/ModelForm/form-fields';
import { UserModel } from '../../../User/model';

import {
  handleSubmit,
  ISendInvitationFormProps,
  ISendInvitationFormValues,
  mapPropsToValues,
  validate,
} from './formik';

export const SendInvitationWithFormik = withFormik<ISendInvitationFormProps, ISendInvitationFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})((props) => {
  const { steps, values, setFieldValue, isSubmitting } = props;
  const disabled = isSubmitting || props.disabled;

  return (
    <>
      <Typography.Paragraph>
        The invitation will include instructions with the following information:
      </Typography.Paragraph>
      {steps}
      <Divider />
      <Form onSubmit={props.handleSubmit}>
        <Radio.Group
          value={values.messageMethod}
          style={{ display: 'block', marginBottom: 20 }}
          onChange={e => {
            setFieldValue('messageMethod', e.target.value);
          }}
          disabled={disabled}
        >
          {[
            { label: 'Text Message', value: MessageMethod.SMS },
            { label: 'Email', value: MessageMethod.EMAIL },
          ].map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
        {values.messageMethod === MessageMethod.SMS && (
          <FormItem {...props}
            formItemLayout={{}}
            isNew={false}
            required
            field={UserModel.introspection.getField('phoneNumber')}
          >
            <PhoneNumberField
              value={values.phoneNumber}
              onChange={(value) => setFieldValue('phoneNumber', value)}
              disabled={disabled}
            />
          </FormItem>
        )}
        {values.messageMethod === MessageMethod.EMAIL && (
          <TextField
            {...props}
            formItemLayout={{}}
            isNew={false}
            required
            field={UserModel.introspection.getField('email')}
            disabled={disabled}
          />
        )}
        {values.invitationSent && !isSubmitting && (
          <Alert
            type='success'
            showIcon
            message='Invitation sent successfully!'
            style={{ marginBottom: 25 }}
          />
        )}
        <section style={{
          overflow: 'hidden',
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        }}>
          <Button
            htmlType='submit'
            type='primary'
            size='large'
            loading={isSubmitting}
            disabled={disabled}
          >
            {isSubmitting ? 'Sending...' : 'Send Invitation'}
          </Button>
        </section>
      </Form>
    </>
  );
});
