import React from 'react';

import { TableActions } from '../../../../components/TableActions/TableActions';
import {
  deleteSmartLockPinCodesDocument,
  deleteSmartLockPinCodesMutation,
  deleteSmartLockPinCodesMutationVariables,
} from '../../../../graphql/api/generated';
import { PermissionKey_enum } from '../../../../graphql/hasura/generated';
import { authentication } from '../../../../stores';
import type { IModelAction } from '../../../typings';

import type { IAugustMobileAccessUser } from './typings';

interface IAugustMobileAccessActionsProps {
  smartLockId: string;
  mobileAccessUser: IAugustMobileAccessUser;
}

export const AugustMobileAccessActions: React.FC<IAugustMobileAccessActionsProps> = (props) => {
  const { smartLockId, mobileAccessUser } = props;
  const { augustLock, augustLockUser, userRole } = mobileAccessUser;
  const { pinCodeStatus } = augustLockUser;

  const assignedToUserId = userRole?.assignedToUser?.userId;

  const canDelete = (
    pinCodeStatus === 'created' ||
    augustLock.Bridge?.status.current === 'online'
  );

  const actionLabel = canDelete ? 'Delete PIN Code' : 'Mark PIN Code as Expired';

  const deletePinCodeAction: IModelAction<IAugustMobileAccessUser> = {
    label: () => actionLabel,
    enabledByModel: () => (
      authentication.hasPermission(PermissionKey_enum.SmartLock_ManagePinCodes)
    ),
    enabledByRow: () => (
      !!assignedToUserId &&
      (pinCodeStatus === 'created' || pinCodeStatus === 'loaded')
    ),
    confirmation: {
      title: () => actionLabel,
      content: () => (
        canDelete
          ? 'Are you sure you want to delete this PIN code?'
          : 'Are you sure you want to mark this PIN code as Expired?'
      ),
    },
    executes: async (__, { apiClient }) => {
      await apiClient.mutate<
        deleteSmartLockPinCodesMutation,
        deleteSmartLockPinCodesMutationVariables
      >({
        mutation: deleteSmartLockPinCodesDocument,
        variables: {
          input: {
            smartLockIds: [smartLockId],
            userId: assignedToUserId as string,
          },
        },
      });

      await apiClient.resetStore();
    },
  };

  return <TableActions row={mobileAccessUser} actions={[deletePinCodeAction]} />;
};
