import { CheckOutlined } from '@ant-design/icons';
import { Alert, Divider, Table, Typography } from 'antd';
import React from 'react';

import {
  GrantableRoleFragment,
  PermissionKey_enum,
  PermissionScope_enum,
} from '../../../graphql/hasura/generated';
import RolePermissionModal from '../../../models/RolePermission/components/RolePermissionModal';

import { IRegisteredUserFormMergedProps } from './formik';
import { useGrantableRoles } from './useGrantableRoles';

export const SelectRoleSection: React.FC<IRegisteredUserFormMergedProps> = (props) => {
  const { values: formikValues, setFieldValue, isSubmitting } = props;
  const { permissionScope, scopedIds } = formikValues;

  const roleId = formikValues.role?.roleId;

  const setRole = (nextRole: GrantableRoleFragment | null) => {
    if (isSubmitting) {
      return; // Disable selection
    }

    setFieldValue('role', nextRole);

    if (nextRole?.roleId !== roleId) {
      // Reset
      setFieldValue('expiresAt', null);
      setFieldValue('reason', null);
      setFieldValue('notes', null);
    }
  };

  const { warnings, loading, roles } = useGrantableRoles({
    permissionScope,
    scopedIds,
    onRolesChanged: (changedRoles) => {
      if (changedRoles.length === 1) {
        setRole(changedRoles[0]); // Preselect role
      }

      if (roleId && !changedRoles.some(r => r.roleId === roleId)) {
        setRole(null); // Unselect role if not available within organization
      }
    },
  });

  const shouldDisplay = !!scopedIds.length || permissionScope === PermissionScope_enum.GLOBAL;

  if (!shouldDisplay) {
    return null;
  }

  return (
    <>
      <Divider />
      <Typography.Title level={4}>
        Select role
      </Typography.Title>
      <Typography.Paragraph>
        Please select an appropriate role for the user based on the desired permissions.
      </Typography.Paragraph>
      <div style={{ marginBottom: 60 }}>
        <Table
          loading={loading}
          columns={[
            {
              title: 'Role Name',
              render: (_, { name }) => name,
            },
            {
              title: 'Description',
              render: (_, { description }) => description,
            },
            {
              title: 'Admin Panel Access',
              render: (_, { rolePermissions }) => {
                return rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.Session_AdminPanel)
                  ? <CheckOutlined />
                  : null;
              },
            },
            {
              title: 'App Access',
              render: (_, { rolePermissions }) => {
                return rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.Session_AccessApp)
                  ? <CheckOutlined />
                  : null;
              },
            },
            {
              title: 'Total Permissions',
              render: (_, role) => {
                const permissionsCount = role.rolePermissions.length;

                if (permissionsCount === 0) {
                  return 0;
                }

                return (
                  <RolePermissionModal
                    roleId={role.roleId}
                    roleName={role.name}
                    permissionScope={role.permissionScope}
                  >
                    <>
                      <span>{permissionsCount}</span>&nbsp;
                      <a style={{ fontSize: '13px' }}>(View)</a>
                    </>
                  </RolePermissionModal>
                );
              },
            },
          ]}
          dataSource={roles}
          onRow={(role) => {
            return {
              onClick: () => setRole(role),
            };
          }}
          rowSelection={{
            type: 'radio',
            onSelect: (role) => setRole(role),
            selectedRowKeys: roleId ? [roleId] : [],
            getCheckboxProps: () => ({ disabled: isSubmitting }),
          }}
          rowKey='roleId'
          rowClassName={() => isSubmitting ? 'disabled-row' : 'selectable-row'}
          style={{ paddingTop: 20 }}
          pagination={false}
        />
        {warnings.length > 0 && (
          <div style={{ marginTop: 30 }}>
            {warnings.map(warning => (
              <Alert
                key={warning}
                showIcon
                type='warning'
                message={warning}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
