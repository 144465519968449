import React from 'react';

import { SplitDescriptions } from '../../../components/SplitDescriptions';
import { HubDetailsFragment, usegetHubDetailsQuery } from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import { createdAtColumn, updatedAtColumn } from '../../common/columns';
import * as columns from '../columns';

const HubDetailsLayout = createDetailsLayout<HubDetailsFragment>();

// @TODO: Make this the default settings for any model's details page
export const HubDetailsPage: React.FC<any> = () => {
  return (
    <HubDetailsLayout
      modelName='Hub'
      useQuery={(hubId) => {
        return usegetHubDetailsQuery({
          variables: { hubId },
        });
      }}
      body={(hub) => {
        return (
          <SplitDescriptions
            row={hub}
            leftSideColumns={[
              columns.propertyColumn,
              columns.nameColumn,
              columns.deviceTypeColumn,
              columns.activationStatusColumn,
              createdAtColumn,
              updatedAtColumn,
            ]}
            rightSideColumns={[
              columns.portColumn,
              columns.macAddressColumn,
              columns.ipAddressColumn,
              columns.installedAtColumn,
              columns.locationColumn,
              hub.hubVersion ? columns.hubVersionColumn : null,
              hub.cliVersion ? columns.cliVersionColumn : null,
              hub.firmwareVersion ? columns.firmwareVersionColumn : null,
            ]}
          />
        );
      }}
    />
  );
};

export default HubDetailsPage;
