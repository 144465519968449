import { Form } from '@ant-design/compatible';
import { Checkbox, Typography } from 'antd';
import React from 'react';

import PhoneNumberField from '../../../components/PhoneNumberField';
import { PermissionKey_enum } from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { FormItem, TextField } from '../../ModelForm/form-fields';

import { IRegisteredUserFormMergedProps } from './formik';

export const NewUserSection: React.FC<IRegisteredUserFormMergedProps> = (props) => {
  const { values, setFieldValue, isSubmitting, UserModel } = props;
  const canSendSms = authentication.hasPermission(PermissionKey_enum.User_SendMessage);

  const getFieldConfig = (fieldName: string) => {
    const introspectionField = UserModel.introspection.getField(fieldName);
    const formOptions = UserModel.formOptions?.fields.find(f => f.fieldName === fieldName);

    return { introspectionField, formOptions };
  };

  const firstNameField = getFieldConfig('firstName');
  const lastNameField = getFieldConfig('lastName');
  const phoneNumberField = getFieldConfig('phoneNumber');
  const emailField = getFieldConfig('email');

  return (
    <>
      <Typography.Paragraph style={{ paddingBottom: 20 }}>
        Please provide a name, phone number, and email that will be used to sign in to Chirp.
      </Typography.Paragraph>

      <TextField
        {...props}
        required
        isNew
        field={firstNameField.introspectionField}
        maxLength={firstNameField.formOptions?.maxLength}
      />

      <TextField
        {...props}
        required
        isNew
        field={lastNameField.introspectionField}
        maxLength={lastNameField.formOptions?.maxLength}
      />

      <FormItem
        {...props}
        isNew
        field={phoneNumberField.introspectionField}
        help={<p className='help-text'>Phone number or email address must be provided</p>}
      >
        <PhoneNumberField
          value={values.phoneNumber}
          onChange={(value) => props.setFieldValue('phoneNumber', value)}
          disabled={props.isSubmitting}
        />
      </FormItem>

      <TextField
        {...props}
        isNew
        field={emailField.introspectionField}
        maxLength={emailField.formOptions?.maxLength}
      />

      {canSendSms && (
        <Form.Item wrapperCol={{ offset: 7 }}>
          <Checkbox
            checked={values.sendSms}
            disabled={isSubmitting}
            onChange={(e) => setFieldValue('sendSms', e.target.checked)}
          >
            Send SMS invitation with link to download Chirp Access App
          </Checkbox>
        </Form.Item>
      )}
    </>
  );
};
