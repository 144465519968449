import { Tag } from 'antd';
import moment from 'moment';
import React from 'react';

import { TextWithIconTooltip } from '../../components/TextWithIconTooltip';
import { VendorPasscodeTableFragment } from '../../graphql/api/generated';
import { formatTimestamp } from '../../utils';
import { UserModel } from '../User/model';

import model from './model';

export const assignedToColumn = model.createColumn<VendorPasscodeTableFragment>({
  title: 'Assigned To',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'assignedToUserId',
    model: UserModel,
  },
  render: ({ assignedToUser }) => UserModel.routes.renderRowLink(assignedToUser),
});

export const codeColumn = model.createColumn<VendorPasscodeTableFragment>({
  title: 'Code',
  render: ({ code }) => code,
});

export const createdByColumn = model.createColumn<VendorPasscodeTableFragment>({
  title: 'Created By',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'createdByUserId',
    model: UserModel,
  },
  render: ({ createdByUser }) => UserModel.routes.renderRowLink(createdByUser),
});

export const updatedAtColumn = model.createColumn<VendorPasscodeTableFragment>({
  title: 'Updated',
  dataIndex: 'updatedAt',
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
  render: ({ updatedAt }) => {
    const formattedTime = formatTimestamp(updatedAt);

    const oneYearAgoTime = moment(new Date()).subtract(365, 'days').toDate().getTime();
    const updatedAtTime = new Date(updatedAt).getTime();

    if (updatedAtTime < oneYearAgoTime) {
      return (
        <>
          <div style={{ marginBottom: 5 }}>{formattedTime}</div>
          <TextWithIconTooltip
            text={(
              <Tag
                style={{ color: 'rgba(0, 0, 0, 0.85)', marginRight: 0 }}
                color='gold'
              >
                Regeneration Recommended
              </Tag>
            )}
            tooltip={{
              title: 'For security purposes, we typically recommend generating a new vendor passcode each year.',
            }}
          />
        </>
      );
    }

    return formattedTime;
  },
});
