import {
  ApartmentOutlined,
  ApiOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useObservable, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { PermissionKey_enum } from '../../graphql/hasura/generated';
import { ModelsContext } from '../../hooks/providers';
import { IModel } from '../../models/typings';
import { appData, authentication } from '../../stores';

const { SubMenu } = Menu;

function getMenuItemProps(pathname: string, path: string) {
  return {
    className: pathname.includes(path) ? 'ant-menu-item-selected' : undefined,
    style: { marginBottom: 2, marginTop: 2 },
  };
}

interface IMenuItem {
  title?: string;
  icon: JSX.Element;
  path?: string;
  enabled?: () => boolean;
  model?: IModel;
  models?: IModel[];
}

export const SideMenu = () => {
  const { pathname } = useLocation();
  const { getModel } = useContext(ModelsContext);

  useObservable(appData);

  const menuItems: IMenuItem[] = [
    {
      title: 'Dashboard',
      icon: <DashboardOutlined />,
      path: '/dashboard',
      enabled: () => (
        authentication.hasPermission(PermissionKey_enum.User_Metrics) ||
        authentication.hasPermission(PermissionKey_enum.SmartLock_AugustSdkFeesReport)
      ),
    },
    {
      icon: <ApartmentOutlined />,
      model: getModel('Organization'),
    },
    {
      title: 'Places',
      icon: <EnvironmentOutlined />,
      models: [
        getModel('Property'),
        getModel('Unit'),
        getModel('UnitGroup'),
        getModel('AccessPoint'),
      ],
    },
    {
      title: 'People/Access',
      icon: <UserOutlined />,
      models: [
        getModel('User'),
        getModel('Role'),
        getModel('UserRole'),
        getModel('Credential'),
        getModel('PinCode'),
        getModel('VendorPasscode'),
        getModel('Session'),
      ],
    },
    {
      title: 'Hardware',
      icon: <ApiOutlined />,
      models: [
        getModel('Hub'),
        getModel('Relay'),
        getModel('Beacon'),
        getModel('SmartLock'),
      ],
    },
    {
      title: 'Activity',
      icon: <ClockCircleOutlined />,
      models: [
        getModel('RelayLog'),
        getModel('SmartLockLog'),
      ],
    },
    {
      icon: <CheckSquareOutlined />,
      model: getModel('Ticket'),
      // @TODO: Display badge
    },
  ];

  // Fall back to all submenus opened
  const defaultOpenKeys = Object.entries(menuItems)
    .map(([, v]) => v.title)
    .filter(Boolean) as string[];

  return useObserver(() => {
    return (
      <Menu
        mode='inline'
        theme='dark'
        defaultOpenKeys={appData.menuOpenKeys || defaultOpenKeys}
        openKeys={appData.menuOpenKeys || defaultOpenKeys}
        onOpenChange={(openKeys) => appData.menuOpenKeys = (openKeys as string[])}
      >
        {menuItems.map((item) => {
          const { icon, enabled } = item;

          if (typeof enabled === 'function' && !enabled()) {
            return null;
          }

          if (item.models) {
            const subMenuTitle = item.title;

            if (item.models.every(m => !m.routes.defaultRoute.enabled())) {
              return null;
            }

            return (
              <SubMenu key={subMenuTitle} icon={icon} title={subMenuTitle}>
                {item.models.map((model) => {
                  const route = model.routes.defaultRoute;
                  const menuItemTitle = model.names.pluralDisplayName;

                  if (!route.enabled()) {
                    return null;
                  }

                  return (
                    <Menu.Item key={menuItemTitle} {...getMenuItemProps(pathname, route.path)}>
                      <Link to={route.path}>{menuItemTitle}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }

          if (item.path || item.model) {
            const title = item.title || item.model?.names?.pluralDisplayName;
            const path = item.path || item.model?.routes?.defaultRoute?.path;

            if (item.model) {
              const route = item.model.routes.defaultRoute;

              if (!route.enabled()) {
                return null;
              }
            }

            if (!path) {
              return null;
            }

            return (
              <Menu.Item key={title} icon={icon} {...getMenuItemProps(pathname, path)}>
                <Link to={path}>{title}</Link>
              </Menu.Item>
            );
          }

          return null;
        })}
      </Menu>
    );
  });
};

export default SideMenu;
