import {
  AccessPoint_bool_exp,
  Organization_bool_exp,
  Property_bool_exp,
  Unit_bool_exp,
  usegetAccessPointsWithTypeQuery,
  usegetOrganizationsQuery,
  usegetPropertiesQuery,
  usegetUnitsQuery,
} from '../../../../graphql/hasura/generated';

import { IScopeQueriesResult, IScopeQueryFilters } from './typings';

const fetchPolicy = 'cache-first';

export function useScopeQueries(filters: IScopeQueryFilters) {
  const organizationsQueryResult = usegetOrganizationsQuery({
    fetchPolicy,
    skip: !filters.organizationFilter,
    variables: {
      where: filters.organizationFilter as Organization_bool_exp,
    },
  });

  const propertiesQueryResult = usegetPropertiesQuery({
    fetchPolicy,
    skip: !filters.propertyFilter,
    variables: {
      where: filters.propertyFilter as Property_bool_exp,
    },
  });

  const unitsQueryResult = usegetUnitsQuery({
    fetchPolicy,
    skip: !filters.unitFilter,
    variables: {
      where: filters.unitFilter as Unit_bool_exp,
    },
  });

  const accessPointsQueryResult = usegetAccessPointsWithTypeQuery({
    fetchPolicy,
    skip: !filters.accessPointFilter,
    variables: {
      where: filters.accessPointFilter as AccessPoint_bool_exp,
    },
  });

  const scopeQueriesResult: IScopeQueriesResult = {
    organizations: organizationsQueryResult.data?.organizations,
    properties: propertiesQueryResult.data?.properties || [],
    units: unitsQueryResult.data?.units || [],
    accessPoints: accessPointsQueryResult.data?.accessPoints || [],
  };

  return {
    ...scopeQueriesResult,
    loading: (
      organizationsQueryResult.loading ||
      propertiesQueryResult.loading ||
      unitsQueryResult.loading ||
      accessPointsQueryResult.loading
    ),
  };
}
