import React from 'react';

import {
  getVendorPasscodesDocument,
  getVendorPasscodesTotalDocument,
  VendorPasscodeTableFragment,
  VendorPasscodeTableFragmentDoc,
} from '../../graphql/api/generated';
import { useMainApiTableData } from '../../hooks/useTableManager';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

const description = () => {
  return (
    <>
      Vendors can scan a Smart Sign and enter their assigned passcode in the web app to start unlocking access points at the property.
    </>
  );
};

model.actions.setDefaultActions([actions.regenerateAction]);

const commonTableConfig = {
  description,
  useTableData: useMainApiTableData({
    totalOperation: getVendorPasscodesTotalDocument,
    listOperation: getVendorPasscodesDocument,
  }),
  fragment: VendorPasscodeTableFragmentDoc, // @TODO: Not used, but required by config
};

const commonColumns = [
  columns.codeColumn,
  columns.createdByColumn,
  createdAtColumn,
  columns.updatedAtColumn,
];

export const VendorPasscodesMainTable = model.createTable<VendorPasscodeTableFragment>({
  ...commonTableConfig,
  columns: [
    columns.assignedToColumn,
    ...commonColumns,
  ],
});

export const VendorPasscodesByPropertyTable = model.createTable<VendorPasscodeTableFragment, string>({
  ...commonTableConfig,
  fixedQueryFilters: propertyId => ({ propertyId }),
  columns: [
    columns.assignedToColumn,
    ...commonColumns,
  ],
  fetchPolicy: 'cache-first',
});


export const VendorPasscodesByUserTable = model.createTable<VendorPasscodeTableFragment, string>({
  ...commonTableConfig,
  title: 'Vendor Passcode',
  fixedQueryFilters: userId => ({ assignedToUserId: userId }),
  columns: [
    ...commonColumns,
  ],
  fetchPolicy: 'cache-first',
});
