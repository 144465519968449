import { Button, Modal, notification, Radio, Typography } from 'antd';
import React, { FC, useState } from 'react';

import { apiClient } from '../../../graphql/api/apiClient';
import { usegeneratePropertyPinCodeMutation } from '../../../graphql/api/generated';
import {
  usegetGatewayPinCodePropertiesByUserQuery,
  UserDetailsFragment as User,
} from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { displayErrorMessage } from '../../../utils';
import PropertyModel from '../../Property/model';

const RADIO_STYLE = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const GenerateNewPinCodeModal: FC<{
  user: User,
  closeModal: () => void | Promise<void>;
}> = ({ closeModal, user }) => {
  const [propertyId, setPropertyId] = useState<string | null>(null);

  const { data, loading: propertiesLoading } = usegetGatewayPinCodePropertiesByUserQuery({
    client: hasuraClient,
    variables: {
      userId: user.userId,
    },
  });

  const properties = data?.properties ?? [];

  const [generatePropertyPinCodeMutation, { loading: submitting }] = usegeneratePropertyPinCodeMutation({
    client: apiClient,
  });

  const generatePropertyPinCode = async () => {
    try {
      if (!propertyId) {
        return;
      }

      await generatePropertyPinCodeMutation({
        variables: {
          input: {
            propertyId,
            assignedToUserId: user.userId,
          },
        },
      });

      closeModal();

      notification.success({
        message: 'A new PIN code was successfully generated!',
      });
    } catch (error) {
      displayErrorMessage(error as Error);
    }
  };

  if (!propertiesLoading && !properties.length) {
    return (
      <Typography.Paragraph>
        This user does not have access to a property with <strong>PIN Codes</strong>.
      </Typography.Paragraph>
    );
  }

  return (
    <>
      <Typography.Paragraph>
        Generate a new random <strong>PIN Code</strong> for this user at an eligible property.
      </Typography.Paragraph>
      <Radio.Group
        value={propertyId}
        style={{ display: 'block', marginBottom: '20px' }}
        onChange={e => {
          setPropertyId(e.target.value);
        }}
      >
        {properties.map(property => {
          const value = property.propertyId;
          return (
            <Radio style={RADIO_STYLE} value={value} key={value}>
              {PropertyModel.labels.getLabel(property)}
            </Radio>
          );
        })}
      </Radio.Group>
      <Button
        disabled={!propertyId}
        type='primary'
        onClick={generatePropertyPinCode}
        style={{ marginRight: '10px' }}
        loading={submitting}
      >
        Submit
      </Button>
      <Button onClick={closeModal}>Cancel</Button>
    </>
  );
};

const GenerateNewPinCode: FC<{ user: User; }> = ({ user }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
    <Button
      onClick={openModal}
      style={{ margin: '5px', float: 'left' }}
    >
      Generate New PIN Code
    </Button>
    <Modal
      title='Generate New PIN Code'
      visible={visible}
      onCancel={closeModal}
      width='50%'
      footer={null}
    >
      {visible && (
        <GenerateNewPinCodeModal user={user} closeModal={closeModal} />
      )}
    </Modal>
    </>
  );
};

export default GenerateNewPinCode;
