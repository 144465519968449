import { FetchPolicy, NextLink, Operation } from '@apollo/client';
import { fromPromise, toPromise } from '@apollo/client/link/utils';
import * as Sentry from '@sentry/browser';
import axios, { RawAxiosRequestHeaders } from 'axios';
import jwt from 'jsonwebtoken';
import { isEqual, uniq } from 'lodash';
import mem from 'mem';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { GRAPHQL_AUTH_SERVER } from '../config';
import { apiClient, clearApiCache } from '../graphql/api/apiClient';
import {
  renewSessionDocument,
  renewSessionMutationVariables,
  SessionOutputFragment,
  SessionScopeInput,
  signOutDocument,
} from '../graphql/api/generated';
import {
  AccountStatus_enum,
  AllowedPermissionFragment as Permission,
  authenticatedUserDocument,
  AuthenticatedUserFragment as User,
  authenticatedUserQuery,
  authenticatedUserQueryVariables,
  getRolePermissionsAndRelationshipsDocument,
  getRolePermissionsAndRelationshipsQuery,
  getRolePermissionsAndRelationshipsQueryVariables,
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  RoleRelationshipFragment as RoleRelationship,
} from '../graphql/hasura/generated';
import { clearHasuraCache, hasuraClient } from '../graphql/hasura/hasuraClient';
import { clearIntrospectionCache } from '../graphql/introspectSchema/introspect';
import introspectModels from '../graphql/introspectSchema/introspectModels';
import history from '../routes/history';
import { displayErrorMessage } from '../utils';

import { Hydrated } from './hydrate';

class Authentication extends Hydrated {

  @persist @observable public chirpAccessToken: string | null = null;
  @persist @observable public chirpRefreshToken?: string | null = null;
  @persist @observable public chirpUserId: string | null = null;

  @observable public user: User | null = null;
  @observable public userRoles: authenticatedUserQuery['userRoles'] = [];
  @observable public currentPermissions: Permission[] = [];
  @observable public currentRoleRelationships: RoleRelationship[] = [];
  @observable public limitStratisPermissions = false;
  @observable public loading = false;

  // As we don't have a flow control for the access token renewal, by memoizing
  // the method that handles the access token renewal we can eliminate race conditions
  @action public renewSession = mem((input?: SessionScopeInput) => this._renewSession(input), {
    maxAge: 30 * 1000,
  });

  constructor() {
    super('Authentication');
  }

  @computed public get isAuthenticated(): boolean {
    return Boolean(this.chirpAccessToken && this.chirpRefreshToken && this.chirpUserId);
  }

  @computed private get tokenPayload(): any {
    return this.chirpAccessToken ? jwt.decode(this.chirpAccessToken) : null;
  }

  @computed public get hasuraDefaultRole(): string | undefined {
    if (!this.tokenPayload) {
      return;
    }

    return this.tokenPayload['https://hasura.io/jwt/claims']['x-hasura-default-role'];
  }

  @computed public get headers(): RawAxiosRequestHeaders {
    if (!this.chirpAccessToken) {
      return {};
    }

    const headers: RawAxiosRequestHeaders = {
      Authorization: `Bearer ${this.chirpAccessToken}`,
    };

    if (this.hasuraDefaultRole) {
      headers['x-hasura-role'] = this.hasuraDefaultRole;
    }

    return headers;
  }

  @computed public get allowedPermissionScopes(): PermissionScope_enum[] {
    const permissionScopes = this.userRoles.map(u => u.role.permissionScope);

    return [...new Set(permissionScopes)];
  }

  @computed public get currentDataScope() {
    // @TODO: Remove support for "admin" Hasura role
    if (this.hasuraDefaultRole === 'user_global_role' || this.hasuraDefaultRole === 'admin') {
      return {
        permissionScope: PermissionScope_enum.GLOBAL,
        scopedId: null,
        scopeLabel: 'Global Scope',
        userRoles: this.userRoles.filter(u => (
          u.role.permissionScope === PermissionScope_enum.GLOBAL
        )),
      };
    }

    if (this.hasuraDefaultRole === 'user_organization_role') {
      const organizationId: string | null = this.tokenPayload
        ? this.tokenPayload['https://hasura.io/jwt/claims']['x-hasura-organization-id']
        : null;

      const userRoles = this.userRoles.filter(u => (
        u.role.permissionScope === PermissionScope_enum.ORGANIZATION &&
        u.scopedOrganization?.organizationId === organizationId
      ));

      return {
        userRoles,
        permissionScope: organizationId ? PermissionScope_enum.ORGANIZATION : null,
        scopedId: organizationId,
        scopeLabel: organizationId ? userRoles[0]?.scopedOrganization?.name : null,
      };
    }

    if (this.hasuraDefaultRole === 'user_property_role') {
      const propertyId: string | null = this.tokenPayload
        ? this.tokenPayload['https://hasura.io/jwt/claims']['x-hasura-property-id']
        : null;

      const userRoles = this.userRoles.filter(u => (
        u.role.permissionScope === PermissionScope_enum.PROPERTY &&
        u.scopedProperty?.propertyId === propertyId
      ));

      return {
        userRoles,
        permissionScope: propertyId ? PermissionScope_enum.PROPERTY : null,
        scopedId: propertyId,
        scopeLabel: propertyId ? userRoles[0]?.scopedProperty?.name : null,
      };
    }

    return {
      permissionScope: null,
      scopedId: null,
      scopeLabel: null,
      userRoles: [] as authenticatedUserQuery['userRoles'],
    };
  }

  @computed public get currentRoleNames() {
    const roleNames = this.currentDataScope.userRoles.map(u => u.role.name);

    return uniq(roleNames);
  }

  @computed public get readableRoleScopes(): PermissionScope_enum[] {
    if (this.hasPermission(PermissionKey_enum.User_Read)) {
      return Object.values(PermissionScope_enum);
    }

    const readableRoleScopes = this.currentRoleRelationships
      .filter(r => r.canReadUserRole || r.canGrantUserRole)
      .map(r => r.relatedRole.permissionScope);

    if (this.currentDataScope.permissionScope) {
      readableRoleScopes.push(this.currentDataScope.permissionScope);
    }

    return [...new Set(readableRoleScopes)];
  }

  @computed public get grantableRoleScopes(): PermissionScope_enum[] {
    if (this.limitStratisPermissions) {
      return [];
    }

    const grantableRoleScopes = this.currentRoleRelationships
      .filter(r => r.canGrantUserRole)
      .map(r => r.relatedRole.permissionScope);

    if (this.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped)) {
      const nonGlobalScopes = Object.values(PermissionScope_enum)
        .filter(scope => scope !== PermissionScope_enum.GLOBAL);

      grantableRoleScopes.push(...nonGlobalScopes);
    }

    if (this.hasPermission(PermissionKey_enum.UserRole_GrantRevokeGlobal)) {
      grantableRoleScopes.push(PermissionScope_enum.GLOBAL);
    }

    return [...new Set(grantableRoleScopes)];
  }

  @computed public get revocableRoleIds(): string[] {
    if (this.limitStratisPermissions) {
      return [];
    }

    return this.currentRoleRelationships
      .filter(r => r.canRevokeUserRole)
      .map(r => r.relatedRole.roleId);
  }

  public hasPermission(permissionKey: PermissionKey_enum): boolean {
    return this.currentPermissions.some(p => p.key === permissionKey);
  }

  public canGrantRole(roleKey: RoleKey_enum): boolean {
  const canGrantAnyScopedUserRole = this.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped);

    return canGrantAnyScopedUserRole || this.currentRoleRelationships.some(r => (
      r.relatedRole.key === roleKey &&
      r.canReadUserRole &&
      r.canGrantUserRole
    ));
  }

  public async ready() {
    await super.ready();

    if (this.isAuthenticated) {
      const promises: Promise<any>[] = [];

      // If the session was revoked, the user should be logged out on page load
      const renewSessionSilently = async () => {
        try {
          await this.renewSession();
        } catch {
          // Authentication errors will trigger logout.
          // But we should not log out the user if a random server error occurs.
        }
      };

      promises.push(renewSessionSilently());

      if (!this.user) {
        promises.push(this.getUserData());
      }

      await Promise.all(promises);
    }
  }

  @action public async logIn(session: SessionOutputFragment) {
    this.loading = true;
    this.storeSession(session);

    await clearHasuraCache();
    await introspectModels();
    await this.getUserData();

    this.loading = false;
  }

  @action public async changeDataScope(input?: SessionScopeInput | null) {
    this.loading = true;

    try {
      await clearHasuraCache();
      await clearApiCache();
      clearIntrospectionCache();

      // Do not use memoized version. We don't want the result to be cached.
      await this._renewSession(input);
      await introspectModels();
      await this.getRoleData();
    } catch (error) {
      displayErrorMessage(error);
    }

    this.loading = false;
  }

  @action public async logout() {
    try {
      await clearHasuraCache();
      await clearApiCache();
      clearIntrospectionCache();
    } catch {
      // Do nothing
    }

    try {
      // The logout can get triggered multiple times due to simultaneous
      // failed GraphQL calls, so only call the API mutation once.
      if (this.chirpRefreshToken) {
        await apiClient.mutate({
          mutation: signOutDocument,
          variables: {
            chirpRefreshToken: this.chirpRefreshToken,
          },
        });
      }

      Sentry.configureScope((scope) => {
        scope.clear();
      });
    } catch {
      // Do nothing
    }

    this.chirpAccessToken = null;
    this.chirpRefreshToken = null;
    this.chirpUserId = null;
    this.user = null;
    this.userRoles = [];
    this.currentPermissions = [];
    this.currentRoleRelationships = [];

    history.push('/login');
  }

  // @TODO: Handle concurrent session renewals from multiple tabs being opened
  // to avoid "Refresh token mismatch" error
  public renewSessionLink(operation: Operation, forward: NextLink) {
    return fromPromise((async () => {
      try {
        await this.renewSession();
      } catch {
        return operation;
      }

      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          ...this.headers,
        },
      });

      return toPromise(forward(operation));
    })());
  }

  public async refetchUserData() {
    await this.getUserData('network-only');
  }

  @action private storeSession(session: SessionOutputFragment) {
    const { chirpUser } = session;
    const { userId } = chirpUser;

    this.chirpAccessToken = session.chirpAccessToken;
    this.chirpRefreshToken = session.chirpRefreshToken;
    this.chirpUserId = userId;
  }

  @action private async getUserData(fetchPolicy: FetchPolicy = 'cache-first') {
    if (!this.isAuthenticated || !this.chirpUserId || !this.hasuraDefaultRole) {
      return;
    }

    this.loading = true;

    const { data: userData } = await hasuraClient.query<
      authenticatedUserQuery, authenticatedUserQueryVariables
    >({
      fetchPolicy,
      query: authenticatedUserDocument,
      variables: { userId: this.chirpUserId },
    });

    if (userData?.user) {
      const { user, userRoles } = userData;

      try {
        if (user.accountStatus === AccountStatus_enum.DISABLED) {
          throw new Error('Not authorized');
        }

        this.user = user;
        this.userRoles = userRoles;

        await this.getRoleData();
        await this.handleRevokedUserRoles(userRoles);
        await this.handleChangedPermissions();

        this.configureSentryScope();
      } catch {
        await this.logout();
      }
    } else {
      await this.logout(); // User doesn't exist or was deleted
    }

    this.loading = false;
  }

  @action private async getRoleData() {
    if (!this.isAuthenticated || !this.currentDataScope.userRoles.length) {
      return;
    }

    const roleIds = this.currentDataScope.userRoles.map(u => u.role.roleId);
    const uniqueRoleIds = [...new Set(roleIds)];

    const { data } = await hasuraClient.query<
      getRolePermissionsAndRelationshipsQuery,
      getRolePermissionsAndRelationshipsQueryVariables
    >({
      query: getRolePermissionsAndRelationshipsDocument,
      variables: { roleIds: uniqueRoleIds },
    });

    if (this.currentDataScope.permissionScope === PermissionScope_enum.PROPERTY) {
      this.limitStratisPermissions = this.currentDataScope.userRoles.every(({ role }) => (
        role.key === RoleKey_enum.STRATIS_PROPERTY_MANAGER ||
        role.key === RoleKey_enum.STRATIS_OFFICE_STAFF ||
        role.key === RoleKey_enum.STRATIS_MAINTENANCE
      ));
    } else {
      this.limitStratisPermissions = false;
    }

    if (data) {
      this.currentPermissions = data.permissions;
      this.currentRoleRelationships = data.roleRelationships;
    }
  }

  @action private async handleRevokedUserRoles(userRoles: authenticatedUserQuery['userRoles']) {
    if (!userRoles.length) {
      throw new Error('Not authorized');
    }

    if (!this.currentDataScope.userRoles.length) {
      await this.changeDataScope(null);
    }
  }

  @action private async handleChangedPermissions() {
    if (this.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL) {
      return;
    }

    const jwtClaims = this.tokenPayload ? this.tokenPayload['https://hasura.io/jwt/claims'] : {};

    const currentReadableRoleIds = uniq(this.currentRoleRelationships
      .filter(r => r.canReadUserRole)
      .map(r => r.relatedRole.roleId));

    const tokenReadableRoleIds: string[] = (jwtClaims['x-hasura-readable-role-ids'])
      .match(/[\w.-]+/g) || [];

    if (!isEqual(currentReadableRoleIds.sort(), tokenReadableRoleIds.sort())) {
      console.warn('Roles have changed. Renewing session...');
      return await this.renewSession();
    }

    const currentReadPermissions = this.currentPermissions
      .filter(p => p.key.endsWith('_Read'))
      .map(p => p.key);

    const tokenReadPermissions: string[] = [];

    for (const [key, value] of Object.entries(jwtClaims)) {
      if (key.endsWith('_Read')) {
        const isEnabled = value === `{${this.currentDataScope.scopedId}}`;

        if (isEnabled) {
          tokenReadPermissions.push(key.replace('x-hasura-', ''));
        }
      }
    }

    if (!isEqual(currentReadPermissions.sort(), tokenReadPermissions.sort())) {
      console.warn('Permissions have changed. Renewing session...');
      return await this.renewSession();
    }
  }

  // tslint:disable-next-line function-name
  @action private async _renewSession(input?: SessionScopeInput | null): Promise<boolean> {
    if (!this.isAuthenticated) {
      // Prevent mem from storing the result in case we're not authenticated
      throw new Error('Not authenticated');
    }

    const defaultInput: SessionScopeInput | null = (
      input === undefined && this.currentDataScope.permissionScope
    )
      ? {
        permissionScope: this.currentDataScope.permissionScope,
        scopedId: this.currentDataScope.scopedId,
      }
      : null;

    try {
      const result = await axios({
        data: {
          query: renewSessionDocument?.loc?.source.body,
          variables: {
            chirpRefreshToken: this.chirpRefreshToken,
            input: input || defaultInput,
          } as renewSessionMutationVariables,
        },
        headers: { ...this.headers },
        method: 'post',
        url: GRAPHQL_AUTH_SERVER,
      });

      const errors = result.data?.errors;

      if (errors) {
        throw errors[0];
      }

      const { session } = result.data.data;

      if (session) {
        this.storeSession(session);
      }

      return this.isAuthenticated;
    } catch (error) {
      if (error?.extensions?.code === 'UNAUTHENTICATED') {
        await this.logout();
        return false;
      }

      throw error;
    }
  }

  private configureSentryScope() {
    const user = this.user;

    Sentry.configureScope((scope) => {
      if (user) {
        // Exclude PII
        scope.setUser({ id: user.userId });
      }

      scope.setContext('Current Data Scope', {
        permissionScope: this.currentDataScope.permissionScope,
        scopedId: this.currentDataScope.scopedId,
        scopeLabel: this.currentDataScope.scopeLabel,
        userRoleIds: this.currentDataScope.userRoles.map(u => u.userRoleId),
      });
    });
  }
}

export default Authentication;
