import { apiClient } from '../apiClient';
import {
  acceptEulaDocument,
  acceptEulaMutation,
  acceptEulaMutationVariables,
  findOrCreateUserDocument,
  findOrCreateUserMutation,
  findOrCreateUserMutationVariables,
  grantGlobalUserRoleDocument,
  grantGlobalUserRoleMutation,
  grantGlobalUserRoleMutationVariables,
  grantScopedUserRolesDocument,
  grantScopedUserRolesMutation,
  grantScopedUserRolesMutationVariables,
} from '../generated';

// Create strongly typed functions for operations that cannot be called as hooks

export async function acceptEula() {
  const { data } = await apiClient.mutate<acceptEulaMutation, acceptEulaMutationVariables>({
    mutation: acceptEulaDocument
  });

  return data?.user;
}

export async function findOrCreateUser(variables: findOrCreateUserMutationVariables) {
  const { data } = await apiClient.mutate<
    findOrCreateUserMutation,
    findOrCreateUserMutationVariables
  >({
    variables,
    mutation: findOrCreateUserDocument,
  });

  return data?.user;
}

export async function grantGlobalUserRole(variables: grantGlobalUserRoleMutationVariables) {
  const { data } = await apiClient.mutate<
    grantGlobalUserRoleMutation,
    grantGlobalUserRoleMutationVariables
  >({
    variables,
    mutation: grantGlobalUserRoleDocument,
  });

  return data?.grantGlobalUserRole;
}

export async function grantScopedUserRoles(variables: grantScopedUserRolesMutationVariables) {
  const { data } = await apiClient.mutate<
    grantScopedUserRolesMutation,
    grantScopedUserRolesMutationVariables
  >({
    variables,
    mutation: grantScopedUserRolesDocument,
  });

  return data?.grantScopedUserRoles || [];
}
