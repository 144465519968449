import { Spin, Steps } from 'antd';
import React from 'react';

import { TableActions } from '../../../components/TableActions/TableActions';
import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import { apiClient } from '../../../graphql/api/apiClient';
import { usegetVendorPasscodesQuery } from '../../../graphql/api/generated';
import {
  PermissionKey_enum,
  RoleKey_enum,
  UserRoleDetailsFragment as UserRole,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { getDisplayedErrorMessage } from '../../../utils';
import { getDescriptionsFromColumns } from '../../helpers/getDescriptionsFromColumns';
import * as actions from '../../VendorPasscode/actions';
import * as columns from '../../VendorPasscode/columns';

export function shouldDisplayVendorPasscode(userRole: UserRole): boolean {
  const { role, status, assignedToUser } = userRole;

  if (status === UserRoleStatus_enum.EXPIRED || status === UserRoleStatus_enum.REVOKED) {
    return false;
  }

  if (!authentication.hasPermission(PermissionKey_enum.VendorPasscode_Read)) {
    return false;
  }

  return !!assignedToUser && role.key === RoleKey_enum.VENDOR_PASS;
}

export const VendorPasscodeDescriptions: React.FC<{ userRole: UserRole }> = ({ userRole }) => {
  const { assignedToUser } = userRole;
  const skip = !shouldDisplayVendorPasscode(userRole);

  const { data, loading, error } = usegetVendorPasscodesQuery({
    skip,
    variables: {
      input: {
        filters: {
          assignedToUserId: assignedToUser?.userId,
        },
      },
    },
    client: apiClient,
  });

  const vendorPasscode = data?.vendorPasscodes?.list?.[0] || null;

  if (!skip) {
    return (
      <>
        <div style={{ float: 'right' }}>
          {!loading && !error && (
            <TableActions
              row={{ assignedToUser }}
              actions={[actions.regenerateAction]}
            />
          )}
        </div>
        <div style={{ float: 'left' }}>
          {loading && <Spin />}
          {!loading && !vendorPasscode && (
            <Steps size='small'>
              <Steps.Step
                status='error'
                title={error ? getDisplayedErrorMessage(error) : 'This user has no Vendor Passcode'}
              />
            </Steps>
          )}
          {vendorPasscode && (
            <TableCellDescriptions
              descriptions={getDescriptionsFromColumns(vendorPasscode, [
                columns.codeColumn,
                columns.createdByColumn,
                columns.updatedAtColumn,
              ])}
            />
          )}
        </div>
      </>
    );
  }

  return null;
};
