import {
  getOrganizationsQuery,
  GrantableRoleFragment,
  RoleKey_enum,
} from '../../../../graphql/hasura/generated';

import { IWarningFlags } from './typings';

interface IGetWarningsArgs {
  organizations: getOrganizationsQuery['organizations'];
  grantableRoles: GrantableRoleFragment[];
  warningFlags: IWarningFlags;
}

export function getWarnings(args: IGetWarningsArgs) {
  const { organizations, grantableRoles, warningFlags } = args;
  const warnings: string[] = [];

  if (organizations.length > 1) {
    warnings.push(
      'Custom organization roles not available. ' +
      'Selected scope falls within multiple organizations.'
    );
  }

  if (
    warningFlags.vendorPasscodesNotEnabled &&
    grantableRoles.some(r => r.key === RoleKey_enum.VENDOR_PASS)
  ) {
    warnings.push(
      'Vendor Pass role not available. ' +
      'One or more selected properties do not have Vendor Passcodes enabled.'
    );
  }

  if (warningFlags.controlledByPms) {
    warnings.push(
      'Resident role not available. ' +
      'One or more selected units are controlled by Property Management System.'
    );
  }

  if (warningFlags.someReservationOnly) {
    warnings.push(
      'One or more selected access points are Reservation Only. ' +
      'Reservations can only be made through the Chirp mobile app.'
    );
  }

  if (warningFlags.mixedAmenities) {
    warnings.push(
      'Amenity Access role not available. ' +
      'Selected access points include a mix of amenities and non-amenities.'
    );
  }

  return warnings;
}
