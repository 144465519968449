import { Card, Typography } from 'antd';
import { withFormik } from 'formik';
import React from 'react';

import { RoleKey_enum, usegetGrantableRolesQuery } from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';
import { TextField } from '../../ModelForm/form-fields';
import { AdditionalContextSection } from '../shared-form/AdditionalContextSection';
import { DurationOfAccessSection } from '../shared-form/DurationOfAccessSection';
import { SubmitFormSection } from '../shared-form/SubmitFormSection';

import {
  handleSubmit,
  ISgtUserFormProps,
  ISgtUserFormValues,
  mapPropsToValues,
  validate,
} from './formik';
import { PinCodesSection } from './PinCodesSection';

export const SgtUserForm = withFormik<ISgtUserFormProps, ISgtUserFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: false,
  validateOnChange: true,
})((props) => {
  const { UserRoleModel } = props;

  const { loading } = usegetGrantableRolesQuery({
    variables: {
      where: {
        key: { _eq: RoleKey_enum.GUEST_PROPERTY_KEY },
      },
    },
    onCompleted: ({ roles }) => {
      const [role] = roles;

      if (role) {
        props.setFieldValue('role', role);
      }
    },
    onError: (error) => displayErrorMessage(error),
  });

  return (
    <>
      <Card bordered={false} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Typography.Paragraph>
          Generate a temporary guest pass for property access (and optionally unit access)
          without requiring the mobile app.
        </Typography.Paragraph>
        <TextField
          {...props}
          formItemLayout={{}}
          className='label-align-top'
          required
          isNew
          label='Guest Name'
          field={UserRoleModel.introspection.getField('nickname')}
          maxLength={100}
        />
      </Card>
      <Card loading={loading} bordered={false} style={{ marginTop: 25, marginBottom: 75 }}>
        <PinCodesSection {...props} />
        <DurationOfAccessSection {...props} scheduleAllowed={false} />
        <AdditionalContextSection {...props} />
        <SubmitFormSection {...props} />
      </Card>
    </>
  );
});
