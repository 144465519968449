// tslint:disable no-var-requires
import Model from './Model';

export const models: Model<any>[] = [
  require('./AccessPoint/model').default,
  require('./AccessPointRelay/model').default,
  require('./AccessPointRole/model').default,
  require('./Beacon/model').default,
  require('./Credential/model').default,
  require('./Favorite/model').default,
  require('./Hub/model').default,
  require('./Image/model').default,
  require('./Organization/model').default,
  require('./Permission/model').default,
  require('./PinCode/model').default,
  require('./Property/model').default,
  require('./Relay/model').default,
  require('./RelayLog/model').default,
  require('./Role/model').default,
  require('./RoleRelationship/model').default,
  require('./Session/model').default,
  require('./SmartLock/model').default,
  require('./SmartLockLog/model').default,
  require('./Ticket/model').default,
  require('./Unit/model').default,
  require('./UnitGroup/model').default,
  require('./User/model').default,
  require('./UserRole/model').default,
  require('./VendorPasscode/model').default,
];

export const getModel = (schemaName: string) => {
  const model = models.find(m => m.names.schemaName === schemaName);

  if (!model) {
    throw new Error(`Unable to find model: ${schemaName}`);
  }

  return model;
};
