import { Input } from 'antd';
import React from 'react';

import { formatPhoneNumberInput, parsePhoneNumber } from '../../utils';

interface IPhoneNumberFieldProps {
  value?: string | number | undefined | null;
  onChange: (value: string | null | undefined) => any;
  disabled?: boolean;
}

export const PhoneNumberField: React.FC<IPhoneNumberFieldProps> = (props) => {
  const { value, onChange, disabled } = props;

  return (
    <Input
      value={typeof value === 'string' ? formatPhoneNumberInput(value) : ''}
      onChange={(e: React.ChangeEvent<any>) => {
        const parsedPhoneNumber = parsePhoneNumber(e.target.value);
        onChange(parsedPhoneNumber || null);
      }}
      type='text'
      disabled={disabled}
      maxLength={20}
    />
  );
};

export default PhoneNumberField;
