import React, { useContext, useState } from 'react';

import { ModelsContext } from '../../hooks/providers';
import BasicLayout from '../../layouts/BasicLayout';

import { RegisteredUserForm } from './registered-user-form';
import { usePresetFormValues } from './registered-user-form/usePresetFormValues';
import { SgtUserForm } from './sgt-user-form';
import { UserFormTabs } from './shared-form/UserFormTabs';

export const GrantAccessPage: React.FC<{}> = () => {
  const presetFormValues = usePresetFormValues();

  const { getModel } = useContext(ModelsContext);
  const UserModel = getModel('User');
  const UserRoleModel = getModel('UserRole');
  const PropertyModel = getModel('Property');

  const presetTab = UserModel.permissions.canCreate() && !presetFormValues.presetUserId
    ? 'NEW_USER'
    : 'EXISTING_USER';
  const [activeTab, setActiveTab] = useState<string>(presetTab);

  return (
    <BasicLayout pageTitle='Grant Access'>
      <UserFormTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        UserModel={UserModel}
      />
      {(activeTab === 'NEW_USER' || activeTab === 'EXISTING_USER') && (
        <RegisteredUserForm
          newUser={activeTab === 'NEW_USER'}
          UserModel={UserModel}
          UserRoleModel={UserRoleModel}
          {...presetFormValues}
        />
      )}
      {(activeTab === 'SGT_USER') && (
        <SgtUserForm UserRoleModel={UserRoleModel} PropertyModel={PropertyModel} />
      )}
    </BasicLayout>
  );
};
