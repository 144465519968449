import React from 'react';

import { IModelAction } from '../../models/typings';

import TableAction from './TableAction';

export interface ITableActionsProps<TRow = any> {
  actions: IModelAction<TRow>[];
  row: TRow;
  style?: React.CSSProperties;
}

export const TableActions: React.FC<ITableActionsProps> = ({ actions, row, style }) => {
  const enabledActions = actions.filter(a => a.enabledByModel() && a.enabledByRow(row));

  if (!enabledActions.length) {
    return null;
  }

  return (
    <div style={{ whiteSpace: 'nowrap', ...style }}>
      {enabledActions.map((action, index) => (
        <TableAction
          key={index}
          action={action}
          row={row}
          divider={index !== 0}
        />
      ))}
    </div>
  );
}

export default TableActions;
