import { VendorPasscodeFiltersInput } from '../../graphql/api/generated';
import { PermissionKey_enum } from '../../graphql/hasura/generated';
import Model from '../Model';

export const VendorPasscodeModel = new Model<VendorPasscodeFiltersInput>({
  names: {
    schemaName: 'VendorPasscode',
    displayName: 'Vendor Passcode',
    pluralDisplayName: 'Vendor Passcodes',
  },

  permissionsOptions: {
    canRead: ({ hasPermission }) => hasPermission(PermissionKey_enum.VendorPasscode_Read),
  },
});

export default VendorPasscodeModel;
