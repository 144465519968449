import { notification } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  revokeUserRoleDocument,
  revokeUserRoleMutation,
  revokeUserRoleMutationVariables,
  RoleKey_enum,
  UnitUserRoleAuditTableFragment,
  UserRoleActionFragment,
  UserRoleStatus_enum,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { updateAccountStatusAction } from '../User/actions';

import { SendVendorPassInvitationForm } from './components/SendInvitationForm';
import model from './model';

export const sendAction = model.createAction<UserRoleActionFragment>({
  label: () => 'Send',
  description: 'Send invitation to user by phone number or email',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.User_SendMessage) &&
    authentication.canGrantRole(RoleKey_enum.VENDOR_PASS)
  ),
  enabledByRow: ({ role, status,  }) => (
    role.key === RoleKey_enum.VENDOR_PASS &&
    (status === UserRoleStatus_enum.ACTIVE || status === UserRoleStatus_enum.PENDING)
  ),
  renderModalContent: userRole => <SendVendorPassInvitationForm userRole={userRole} />,
  getModalProps: () => ({
    title: 'Send Vendor Pass Invitation',
    width: '60%',
    footer: null,
    destroyOnClose: true,
  }),
});

export const revokeAction = model.createAction<UserRoleActionFragment>({
  label: () => 'Revoke',
  description: `Revokes this user's role`,
  enabledByModel: () => true,
  enabledByRow: (userRole, { limitStratisPermissions }) => {
    if (limitStratisPermissions) {
      return false;
    }

    const { userRoleId, sourceId, status, role, assignedToUser } = userRole;
    const assignedToUserId = assignedToUser?.userId;

    const isAssignedToSelf = assignedToUserId && assignedToUserId === authentication.chirpUserId;
    const isOnlyCurrentUserRole = authentication.currentDataScope.userRoles.some(u => (
      u.userRoleId === userRoleId
    )) && authentication.currentDataScope.userRoles.length === 1;

    const permissionKey = role.permissionScope === PermissionScope_enum.GLOBAL
      ? PermissionKey_enum.UserRole_GrantRevokeGlobal
      : PermissionKey_enum.UserRole_GrantRevokeScoped;

    const canRevokeSelf = isAssignedToSelf && !isOnlyCurrentUserRole;
    const canRevokeOther = (
      !isAssignedToSelf && (
        authentication.hasPermission(permissionKey) ||
        authentication.revocableRoleIds.includes(role.roleId)
      )
    );

    return (
      sourceId === null && // The PMS source will determine whether access should be revoked
      (status === UserRoleStatus_enum.ACTIVE || status === UserRoleStatus_enum.PENDING) &&
      (canRevokeSelf || canRevokeOther)
    );
  },
  confirmation: {
    title: () => 'Revoke Role',
    content: () => `Are you sure you want to revoke this user's role?`,
  },
  async executes({ userRoleId }, { hasuraClient, resetStores }) {
    const { data } = await hasuraClient
      .mutate<revokeUserRoleMutation, revokeUserRoleMutationVariables>({
        mutation: revokeUserRoleDocument,
        variables: {
          userRoleId,
        },
      });

    if (!data?.custom_revokeUserRole.userRole) {
      throw new Error(`Unable to revoke the user's role`);
    }

    await resetStores();

    notification.success({
      message: 'Success',
      description: `The user's role was successfully revoked!`,
    });
  },
});

export const updateUserAccountStatusAction = model.createAction<UnitUserRoleAuditTableFragment>({
  label: ({ assignedToUser }) => {
    return assignedToUser && updateAccountStatusAction.label
      ? updateAccountStatusAction.label(assignedToUser)
      : '';
  },
  description: updateAccountStatusAction.description,
  enabledByModel: updateAccountStatusAction.enabledByModel,
  confirmation: {
    content: ({ assignedToUser }) => (
      assignedToUser && updateAccountStatusAction.confirmation?.content
        ? updateAccountStatusAction.confirmation?.content(assignedToUser)
        : null
    ),
  },
  async executes({ assignedToUser }, actionContext) {
    if (assignedToUser && updateAccountStatusAction.executes) {
      await updateAccountStatusAction.executes(assignedToUser, actionContext);
    }
  },
});
