import { Steps } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  RoleKey_enum,
  UserRoleDetailsFragment as UserRole,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';

export function shouldDisplayPropertyPinCode(userRole: UserRole): boolean {
  const { role, status, pinCodes } = userRole;
  const [propertyPinCode] = pinCodes;

  if (status === UserRoleStatus_enum.EXPIRED || status === UserRoleStatus_enum.REVOKED) {
    return false;
  }

  if (!authentication.hasPermission(PermissionKey_enum.PinCode_Read)) {
    return false;
  }

  return !!propertyPinCode && role.key === RoleKey_enum.GUEST_PROPERTY_KEY;
}

export const PropertyPinCodeDescriptions: React.FC<{ userRole: UserRole }> = ({ userRole }) => {
  if (shouldDisplayPropertyPinCode(userRole)) {
    return (
      <Steps size='small'>
        <Steps.Step
          status='finish'
          title='Enabled for entries with keypads'
        />
      </Steps>
    );
  }

  return null;
};
