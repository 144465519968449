import { ColumnProps } from 'antd/lib/table';
import React from 'react';

import { formatTimestamp, formatUserName } from '../../../../utils';
import { UserModel } from '../../../User/model';
import { AugustPinCodeStatus } from '../../components/AugustPinCodeStatus';

import { hasGlobalUserReadPermission, renderChirpRole } from './column-helpers';
import type { IAugustPinCodeUser } from './typings';

export const pinCodeAccessColumns: ColumnProps<IAugustPinCodeUser>[] = [
  {
    title: 'Name',
    render: (_, { augustPinCode, userRole }) => {
      const userLink = UserModel.routes.renderRowLink(userRole?.assignedToUser);

      if (userLink) {
        return userLink;
      }

      let firstName: string | null | undefined = '';
      let lastName: string | null | undefined = '';

      if (userRole?.nickname) {
        firstName = userRole.nickname;
      } else {
        firstName = augustPinCode.firstName;
        lastName = augustPinCode.lastName === '(Unregistered)' ? '' : augustPinCode.lastName;
        // Hide August last name if they're not allowed to view the Chirp user
        lastName = !userRole && !hasGlobalUserReadPermission() ? lastName?.charAt(0) : lastName;
      }

      return formatUserName({ firstName, lastName }, false);
    },
  },
  {
    title: 'Role',
    render: (_, { role, userRole }) => {
      const selectedUserRole = userRole?.parentUserRole || userRole;

      return renderChirpRole({ role, userRole: selectedUserRole });
    },
  },
  {
    title: 'August User ID',
    render: (_, { augustPinCode }) => augustPinCode.userID,
  },
  {
    title: 'PIN Code',
    render: (_, { augustPinCode }) => {
      return augustPinCode.status ? <AugustPinCodeStatus status={augustPinCode.status} /> : null;
    },
  },
  {
    title: 'Loaded Date',
    render: (_, { augustPinCode }) => {
      return formatTimestamp(augustPinCode.loadedDate);
    },
  },
  {
    title: 'Start Date',
    render: (_, { augustPinCode }) => {
      const { accessStartTime, createdAt } = augustPinCode;

      return formatTimestamp(accessStartTime || createdAt);
    },
  },
  {
    title: 'End Date',
    render: (_, { augustPinCode }) => {
      return formatTimestamp(augustPinCode.accessEndTime);
    },
  },
];
