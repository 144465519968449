import { Card, Spin } from 'antd';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ChirpTable } from '../../../components/ChirpTable';
import { CsvExportButton } from '../../../components/CsvExport/CsvExportButton';
import EnumTag from '../../../components/EnumTag';
import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  usemessageSentFromHubSubscription,
  usesendMessageToHubsMutation,
} from '../../../graphql/api/generated';
import {
  AccessPointLabelFragment,
  GpioMode_enum,
  RelayLabelFragment,
  usegetPropertyDetailsQuery,
  usegetRelaysWithAccessPointInfoQuery,
} from '../../../graphql/hasura/generated';
import BasicLayout from '../../../layouts/BasicLayout';
import { Color, displayErrorMessage, formatTimestamp } from '../../../utils';
import AccessPointModel from '../../AccessPoint/model';
import RelayModel from '../../Relay/model';
import { IChirpTableColumn } from '../../typings';
import model from '../model';

enum DoorStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

interface IRelayDpsStatus {
  relay: RelayLabelFragment;
  accessPoints: AccessPointLabelFragment[];
  position: number;
  status: DoorStatus | null;
  statusUpdatedAt?: string | null;
  lastAlertAt?: string | null;
  action?: string | null,
}

const PropertyDpsMonitoringPage: React.FC<RouteComponentProps<{ propertyId: string }>> = (props) => {
  const { propertyId } = props.match.params;
  const [relaysWithStatus, setRelaysWithStatus] = useState<IRelayDpsStatus[]>([]);

  const { data: propertyData } = usegetPropertyDetailsQuery({
    variables: { propertyId },
  });

  const { data: relaysData, loading } = usegetRelaysWithAccessPointInfoQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        _and: [
          {
            gpioMode: { _eq: GpioMode_enum.INPUT },
          },
          {
            accessPointRelays: {
              accessPoint: {
                propertyId: { _eq: propertyId },
              },
            },
          },
        ],
      },
    },
  });

  const relays = relaysData?.relays || [];
  const property = propertyData ? propertyData.property : null;
  const hubIds = relays.map(r => r.hubId);
  const uniqueHubIds = [...new Set(hubIds)];

  // @TODO: Fix race condition
  const [sendMessageToHubs] = usesendMessageToHubsMutation({
    client: apiClient,
    variables: {
      input: {
        hubIds: uniqueHubIds,
        type: 'MONITORING_DPS',
      },
    },
  });

  useEffect(() => {
    const sortedRelays = orderBy(relays, o => o.position, ['asc']);
    const nextRelaysWithStatus = sortedRelays.map((relay) => {
      const { accessPointRelays, position } = relay;

      return {
        relay,
        position,
        accessPoints: accessPointRelays.map(apr => apr.accessPoint),
        status: null,
      };
    });

    setRelaysWithStatus(nextRelaysWithStatus);

    if (uniqueHubIds.length) {
      sendMessageToHubs().catch(error => displayErrorMessage(error));
    }
  }, [relays.length]);

  usemessageSentFromHubSubscription({
    client: apiClient,
    variables: {
      input: {
        hubIds: uniqueHubIds,
        types: ['MONITORING_DPS'],
      },
    },
    skip: uniqueHubIds.length === 0,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData;
      const payload = data?.messageSentFromHub?.payload;

      if (!!payload) {
        const index = relaysWithStatus.findIndex(r => r.relay.relayId === payload.relayId);

        if (index !== -1) {
          const nextRelaysWithStatus = [...relaysWithStatus];

          nextRelaysWithStatus[index].status = payload.status;
          nextRelaysWithStatus[index].statusUpdatedAt = payload.statusUpdatedAt;
          nextRelaysWithStatus[index].lastAlertAt = payload.lastAlertAt;
          nextRelaysWithStatus[index].action = payload.action;
          setRelaysWithStatus(nextRelaysWithStatus);
        }
      }
    },
  });

  const columns: IChirpTableColumn<IRelayDpsStatus>[] = [
    {
      title: 'Access Point',
      key: 'accessPoints',
      render: ({ accessPoints }) => AccessPointModel.routes.renderRowLinks(accessPoints),
      renderString: ({ accessPoints }) => (
        accessPoints.map((ap) => AccessPointModel.labels.getLabel(ap)).join(', ')
      ),
    },
    {
      title: 'Relay',
      key: 'relay',
      render: ({ relay }) => RelayModel.routes.renderRowLink(relay),
    },
    {
      title: 'Position',
      key: 'position',
      render: ({ position }) => position,
    },
    {
      title: 'Status',
      key: 'status',
      render: ({ status }) => {
        if (!status) {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              <Spin style={{ marginRight: '5px' }} /> Loading...
            </div>
          );
        }

        return (
          <EnumTag
            colorMap={{
              [DoorStatus.CLOSED]: Color.Green,
              [DoorStatus.OPEN]: Color.Red,
            }}
            enumValue={status}
          />
        );
      },
      renderString: ({ status }) => status || 'Loading...',
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ action }) => action,
    },
    {
      title: 'Status Change Date',
      key: 'statusUpdatedAt',
      render: ({ statusUpdatedAt }) => formatTimestamp(statusUpdatedAt),
    },
    {
      title: 'Last Alert Date',
      key: 'lastAlertAt',
      render: ({ lastAlertAt }) => formatTimestamp(lastAlertAt),
    },
  ];

  const pageTitle = 'Door Position Sensor Monitoring';

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        title={(
          <ModelBreadcrumbs
            model={model}
            rowBreadCrumb={{ row: property }}
            customBreadCrumbs={[pageTitle]}
          />
        )}
        bordered={false}
        style={{ marginBottom: '20px' }}
        loading={loading}
        extra={(
          <CsvExportButton
            title={`${pageTitle} - ${property?.name}`}
            rows={relaysWithStatus}
            columns={columns}
            disabled={loading}
          />
        )}
      >
        <ChirpTable
          loading={{
            size: 'large',
            spinning: loading,
          }}
          rowKey={r => r.relay.relayId}
          columns={[...columns]}
          dataSource={relaysWithStatus}
          scroll={{ x: true }}
          pagination={false}
        />
      </Card>
    </BasicLayout>
  );
}

export default PropertyDpsMonitoringPage;
