import { notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  PermissionKey_enum,
  RoleKey_enum,
  TicketRequiredRowFragment,
  TicketStatus_enum,
  TicketType_enum,
  updateTicketStatusDocument,
  updateTicketStatusMutation,
  updateTicketStatusMutationVariables,
} from '../../graphql/hasura/generated';
import {
  buildLockResetUnitKeyUrl,
  buildLowBatteryUnitKeyUrl,
} from '../../pages/GrantAccess/buildGrantAccessPageUrl';
import { authentication } from '../../stores';

import model from './model';

export const updateStatusAction = model.createAction<TicketRequiredRowFragment>({
  label: ({ status }) => {
    return status === TicketStatus_enum.SOLVED
      ? 'Mark as Unsolved'
      : 'Mark as Solved';
  },
  description: 'Update ticket status',
  enabledByModel: ({ hasPermission }) => hasPermission(PermissionKey_enum.Ticket_Update),
  confirmation: {
    content: ({ status }) => (
      status === TicketStatus_enum.SOLVED
        ? 'Are you sure you want to mark this ticket as unsolved?'
        : 'Are you sure you want to mark this ticket as solved?'
    ),
  },
  async executes({ ticketId, status }, { hasuraClient, refetchContext }) {
    const { data } = await hasuraClient
      .mutate<updateTicketStatusMutation, updateTicketStatusMutationVariables>({
        mutation: updateTicketStatusDocument,
        variables: {
          ticketId,
          status: status === TicketStatus_enum.SOLVED
            ? TicketStatus_enum.UNSOLVED
            : TicketStatus_enum.SOLVED,
        },
      });

    if (!data?.custom_updateTicketStatus.ticket) {
      throw new Error(`Unable to update ticket status`);
    }

    await refetchContext();

    notification.success({
      message: 'Success',
      description: `The ticket's status was successfully updated!`,
    });
  },
});

export const createUnitKeyAction = model.createAction<TicketRequiredRowFragment>({
  description: 'Create unit key to solve lock reset ticket',
  enabledByModel: ({ hasPermission, limitStratisPermissions }) => {
    if (
      limitStratisPermissions ||
      // It's not worth loading and checking the unit occupancy for a
      // small subset of users with the "Vacant Units Only" restriction
      hasPermission(PermissionKey_enum.UserRole_GrantVacantUnitsOnly)
    ) {
      return false;
    }

    return authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped) ||
      authentication.currentRoleRelationships.some(r => (
        r.relatedRole.key === RoleKey_enum.STAFF_UNIT_KEY &&
        r.canGrantUserRole
      ));
  },
  enabledByRow: ({ unit, type }) => (
    !!unit && (
      type === TicketType_enum.TURNOVER_LOCK_RESET ||
      type === TicketType_enum.SMART_LOCK_LOW_BATTERY
    )
  ),
  render({ unit, type }) {
    if (!unit) {
      return null;
    }

    let url: string | null = null;

    if (type === TicketType_enum.TURNOVER_LOCK_RESET) {
      url = buildLockResetUnitKeyUrl([unit.unitId]);
    } else if (type === TicketType_enum.SMART_LOCK_LOW_BATTERY) {
      url = buildLowBatteryUnitKeyUrl([unit.unitId]);
    }

    if (!url) {
      return null;
    }

    return (
      <Link to={url}>
        Create Unit Key
      </Link>
    );
  },
});
