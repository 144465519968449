import { Card, Tabs } from 'antd';
import React from 'react';

import { RoleKey_enum } from '../../../graphql/hasura/generated';
import { IModel } from '../../../models/typings';
import { authentication } from '../../../stores';

interface IUserFormTabsProps {
  activeTab: string;
  setActiveTab: (nexActiveTab: string) => any;
  UserModel: IModel
}

export const UserFormTabs: React.FC<IUserFormTabsProps> = (props) => {
  const { activeTab, setActiveTab, UserModel } = props;

  const canCreateRegisteredUsers = UserModel.permissions.canCreate();

  const canGrantGuestPasses = authentication.canGrantRole(RoleKey_enum.GUEST_PROPERTY_KEY);
  const canGrantSgtUsers = authentication.canGrantRole(RoleKey_enum.SMART_LOCK_UNREGISTERED_USER);

  const tabs = [
    {
      key: 'NEW_USER',
      title: 'New User',
      disabled: !canCreateRegisteredUsers,
      hidden: false,
    },
    {
      key: 'EXISTING_USER',
      title: 'Existing User',
      disabled: false,
      hidden: false,
    },
    {
      key: 'SGT_USER',
      title: 'Self-Guided Tour User',
      // @TODO: Eventually we might allow this flow to be used without
      // the ability to grant smart lock access (i.e., property access only)
      disabled: false,
      hidden: !(canGrantGuestPasses && canGrantSgtUsers),
    },
  ];

  return (
    <Card
      bordered={false}
      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <Tabs
        size='large'
        animated={false}
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onChange={(nextActiveTab) => {
          setActiveTab(nextActiveTab);
        }}
        tabBarStyle={{ marginBottom: 0 }}
      >
        {tabs.map(({ key, title, disabled, hidden }) => {
          if (hidden) {
            return null;
          }

          return (
            <Tabs.TabPane
              key={key}
              tab={title}
              disabled={disabled}
            />
          );
        })}
      </Tabs>
    </Card>
  );
};
