
import { Steps } from 'antd';
import React from 'react';

import { UserRoleDetailsFragment, UserRoleStatus_enum } from '../../../../graphql/hasura/generated';
import { getPropertyFeatureFlags } from '../../../../utils';

import { SendInvitationWithFormik } from './SendInvitationWithFormik';

interface ISendSgtInvitationFormProps {
  userRole: UserRoleDetailsFragment;
}

export function getInvitationInfo(userRole: UserRoleDetailsFragment) {
  const propertyFeatureFlags = getPropertyFeatureFlags(userRole.managedWithinProperty);

  const hasPropertyPinCode = !!userRole.pinCodes.length;
  const hasSmartLockPinCodes = userRole.childUserRoles.some(u => (
    !!u.scopedSmartLock &&
    (u.status === UserRoleStatus_enum.ACTIVE || u.status === UserRoleStatus_enum.PENDING)
  ));

  return { propertyFeatureFlags, hasPropertyPinCode, hasSmartLockPinCodes };
}

export const SendSgtInvitationForm: React.FC<ISendSgtInvitationFormProps> = (props) => {
  const { userRole } = props;

  const { propertyFeatureFlags, hasPropertyPinCode, hasSmartLockPinCodes } = getInvitationInfo(userRole);

  const steps = (
    <Steps direction='vertical' size='small' className='send-invitation-steps'>
      <Steps.Step
        status='finish'
        title='Duration of access'
      />
      <Steps.Step
        status={propertyFeatureFlags.SMART_SIGNS ? 'finish' : 'error'}
        title='Guest Pass URL for property access'
      />
      <Steps.Step
        status={hasPropertyPinCode ? 'finish' : 'error'}
        title='PIN code for property access'
      />
      <Steps.Step
        status={hasSmartLockPinCodes ? 'finish' : 'error'}
        title='PIN code for unit access'
      />
    </Steps>
  );

  return (
    <SendInvitationWithFormik
      userRole={userRole}
      steps={steps}
      disabled={!propertyFeatureFlags.SMART_SIGNS && !hasPropertyPinCode && !hasSmartLockPinCodes}
    />
  );
};
