import parsePhoneNumberFromString from 'libphonenumber-js';
import { chunk, groupBy } from 'lodash';
import moment from 'moment-timezone';
import validator from 'validator';

import { findOrCreateUser, grantScopedUserRoles } from '../../graphql/api/operations';
import { RoleKey_enum, UnitLabelFragment, UserRoleStatus_enum } from '../../graphql/hasura/generated';
import { getPropertyDetails, getRoleByKey, getUnits } from '../../graphql/hasura/operations';
import { formatTimestamp } from '../../utils';
import { RowImportStatus } from '../typings';
import { buildingField, findMatchingUnit, unitNumberField } from '../Unit/form-options';
import { activationDateColumn, unitColumn } from '../UserRole/columns';
import { activatesAtField, managedWithinPropertyField, roleField } from '../UserRole/form-options';
import { UserRoleModel } from '../UserRole/model';

import * as columns from './columns';
import model from './model';

const ROWS_PER_BATCH = 25;

interface IParsedRow {
  'Unit Number': string;
  Building?: string;
  'First Name': string;
  'Last Name': string;
  'Phone Number': string;
  Email?: string;
  'Activation Date': string;
}

interface IApplyAllValues {
  managedWithinPropertyId: string;
}

interface ITransformedRow {
  roleId: string;
  scopedUnit: UnitLabelFragment;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  activatesAt: string;
  // These will only be used for rendering the "Activation Date" column
  timezone: string;
  timezoneAbbr: string;
}

interface IImportDependencies {
  roleId: string;
  timezone: string;
  units: UnitLabelFragment[];
}

const formFields = model.createFormFields<IParsedRow>([
  {
    ...managedWithinPropertyField,
  },
  {
    ...roleField,
  },
  {
    ...unitNumberField,
    importOptions: {
      importFieldName: 'Unit Number',
      required: true,
    },
  },
  {
    ...buildingField,
    importOptions: {
      importFieldName: 'Building',
    },
  },
  {
    fieldName: 'firstName',
    maxLength: 50,
    importOptions: {
      importFieldName: 'First Name',
      required: true,
    },
  },
  {
    fieldName: 'lastName',
    maxLength: 50,
    importOptions: {
      importFieldName: 'Last Name',
      required: true,
    },
  },
  {
    fieldName: 'phoneNumber',
    importOptions: {
      importFieldName: 'Phone Number',
      required: true,
      rules: [
        {
          description: 'Must be a valid phone number',
          errorMessage: 'Invalid phone number',
          validate: (parsedRow) => {
            return parsedRow['Phone Number']
              ? parsePhoneNumberFromString(parsedRow['Phone Number'], 'US')?.isValid() || false
              : true;
          },
        },
        {
          description: 'Must be unique',
        },
      ],
    },
  },
  {
    fieldName: 'email',
    maxLength: 320,
    importOptions: {
      importFieldName: 'Email',
      rules: [
        {
          description: 'Must be a valid email address',
          errorMessage: 'Invalid email address',
          validate: (parsedRow) => {
            return parsedRow.Email ? validator.isEmail(parsedRow.Email) : true;
          },
        },
        {
          description: 'Must be unique',
        },
      ],
    },
  },
  {
    ...activatesAtField,
  },
]);

function getGroupKeyByUnit(row: IParsedRow) {
  return [row['Building'] || '', row['Unit Number']].filter(Boolean).join('-');
}

model.setFormOptions<IParsedRow, IApplyAllValues, ITransformedRow, IImportDependencies>({
  fields: formFields,
  importOptions: {
    rowLimit: 3000,
    columns: [
      unitColumn,
      columns.firstNameColumn,
      columns.lastNameColumn,
      columns.phoneNumberColumn,
      columns.emailColumn,
      {
        ...activationDateColumn,
        render: ({ activatesAt, timezone, timezoneAbbr }) => (
          // Render date/time in property timezone
          formatTimestamp(activatesAt, { timezone, timezoneAbbr })
        ),
      },
    ],
    getDependencies: async (rows, { managedWithinPropertyId }) => {
      const role = await getRoleByKey(RoleKey_enum.RESIDENT);
      const property = await getPropertyDetails(managedWithinPropertyId);

      if (!role || !property) {
        throw new Error('Unable to transform CSV rows');
      }

      const units = await getUnits({
        where: {
          propertyId: { _eq: managedWithinPropertyId },
        },
      });

      return {
        units,
        roleId: role.roleId,
        timezone: property.timezone,
      };
    },
    validateRows: (rows, applyAllValues, { units }) => {
      const rowsByUnit = groupBy(rows, r => getGroupKeyByUnit(r.parsedRow));

      for (const row of rows) {
        const { parsedRow } = row;

        const matchingUnit = findMatchingUnit({
          unitNumber: row.parsedRow['Unit Number'],
          building: row.parsedRow['Building'],
        }, units);

        if (!matchingUnit) {
          row.addError({
            message: 'Unable to find matching unit',
            highlightedFieldNames: ['Unit Number', 'Building'],
          });
        }

        if (matchingUnit?.sourceId) {
          row.addError({
            message: 'Resident access for this unit is controlled by a PMS',
            highlightedFieldNames: ['Unit Number', 'Building'],
          });
        }

        const phoneNumber = parsedRow['Phone Number'];
        const email = parsedRow['Email'];

        const groupedResidents = rowsByUnit[getGroupKeyByUnit(parsedRow)];

        const nonUniquePhoneNumber = Boolean(
          phoneNumber &&
          groupedResidents.filter(r => r.parsedRow['Phone Number'] === phoneNumber).length > 1
        );

        const nonUniqueEmail = Boolean(
          email &&
          groupedResidents.filter(r => r.parsedRow['Email'] === email).length > 1
        );

        if (nonUniquePhoneNumber) {
          row.addError({
            errorFieldName: 'Phone Number',
            message: 'Non-unique phone number',
          });
        }

        if (nonUniqueEmail) {
          row.addError({
            errorFieldName: 'Email',
            message: 'Non-unique email',
          });
        }
      }

      return rows;
    },
    transformRows: async (rows, applyAllValues, importDependencies) => {
      const { roleId, timezone, units} = importDependencies;
      const timezoneAbbr = moment.tz(timezone).zoneAbbr();

      for (const row of rows) {
        const { parsedRow } = row;

        const matchingUnit = findMatchingUnit({
          unitNumber: row.parsedRow['Unit Number'],
          building: row.parsedRow['Building'],
        }, units);

        const phoneNumber = parsedRow['Phone Number'];
        const email = parsedRow['Email'];

        const activatesAtMoment = moment(new Date(parsedRow['Activation Date']));
        const isDateOnly = (
          !activatesAtMoment.hours() &&
          !activatesAtMoment.minutes() &&
          !activatesAtMoment.seconds() &&
          !activatesAtMoment.milliseconds()
        );

        // Only keep local time if it's a date with no time set (i.e., defaults to 12am)
        const activatesAt = activatesAtMoment.tz(timezone, isDateOnly).toISOString();

        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'US');
        const e164PhoneNumber = String(parsedPhoneNumber?.number || '');

        row.setTransformedRow({
          timezone,
          timezoneAbbr,
          roleId,
          activatesAt,
          email,
          scopedUnit: matchingUnit as UnitLabelFragment,
          firstName: parsedRow['First Name'],
          lastName: parsedRow['Last Name'],
          phoneNumber: e164PhoneNumber,
        });
      }

      return rows;
    },
    importRows: async (validRows) => {
      const importedUserRoles: { userRoleId: string }[] = [];
      const rowBatches = chunk(validRows, ROWS_PER_BATCH);

      for (const batch of rowBatches) {
        await Promise.all(batch.map(async (row) => {
          const { transformedRow } = row;

          try {
            const user = await findOrCreateUser({
              input: {
                firstName: transformedRow.firstName,
                lastName: transformedRow.lastName,
                phoneNumber: transformedRow.phoneNumber,
                email: transformedRow.email,
              },
            });

            if (!user) {
              throw new Error('Unable to find or create user');
            }

            const scopedUserRoles = await grantScopedUserRoles({
              input: {
                scopedIds: [transformedRow.scopedUnit.unitId],
                roleId: transformedRow.roleId,
                assignedToUserId: user.userId,
                activatesAt: transformedRow.activatesAt,
              },
            });

            importedUserRoles.push(...scopedUserRoles);
            row.setImportState({ status: RowImportStatus.IMPORTED });
          } catch (error) {
            row.setImportState({
              status: RowImportStatus.ERROR,
              errorMessage: error.message,
            });
          }
        }));
      }

      return importedUserRoles;
    },
    getRedirectLabel: () => 'View Imported Residents',
    getRedirectPath: (_, { managedWithinPropertyId }, { roleId }) => {
      const stringifiedParams = JSON.stringify({
        UserRoleStatus: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
        role: [roleId],
        managedWithinProperty: [managedWithinPropertyId],
      });
      const encodedParams = encodeURIComponent(stringifiedParams);

      return `${UserRoleModel.routes.basePath}?table=Unit%20Roles&filters=${encodedParams}`;
    },
    fakeProgress: false,
    sampleCsvFilename: 'sample_residents',
    sampleCsvData: [
      {
        'Unit Number': '1001',
        Building: '1',
        'First Name': 'Jimmy',
        'Last Name': 'McGill',
        'Phone Number': '(281) 555-0001',
        'Email': 'jimmy.mcgill@chirpsystems.com',
        'Activation Date': '04/26/2022',
      },
      {
        'Unit Number': '1001',
        Building: '1',
        'First Name': 'Kim',
        'Last Name': 'Wexler',
        'Phone Number': '(281) 555-0002',
        'Email': 'kim.wexler@chirpsystems.com',
        'Activation Date': '04/26/2022',
      },
      {
        'Unit Number': '2001',
        Building: '2',
        'First Name': 'Howard',
        'Last Name': 'Hamlin',
        'Phone Number': '(281) 555-0003',
        'Email': 'howard.hamlin@chirpsystems.com',
        'Activation Date': '02/08/2022',
      },
      {
        'Unit Number': '2002',
        Building: '2',
        'First Name': 'Ignacio',
        'Last Name': 'Varga',
        'Phone Number': '(281) 555-0004',
        'Email': 'ignacio.varga@chirpsystems.com',
        'Activation Date': '02/16/2022',
      },
      {
        'Unit Number': '2003',
        Building: '2',
        'First Name': 'Eduardo',
        'Last Name': 'Salamanca',
        'Phone Number': '(281) 555-0005',
        'Email': 'eduardo.salamanca@chirpsystems.com',
        'Activation Date': '09/24/2022',
      },
    ]
  },
});
