import React from 'react';

import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import {
  UserRoleActionFragmentDoc,
  UserRoleExpandedFragment,
} from '../../../graphql/hasura/generated';
import { getDescriptionsFromColumns } from '../../helpers/getDescriptionsFromColumns';
import { IModelTableExpandable } from '../../typings';
import * as actions from '../actions';
import * as columns from '../columns';
import model from '../model';

model.actions.setDefaultActions([
  actions.sendAction,
  actions.revokeAction,
], UserRoleActionFragmentDoc);

export const expandable: IModelTableExpandable<UserRoleExpandedFragment> = {
  rowExpandable: ({ sourceId, grantMethod, reason, notes, revokedAt }) => (
    !!(sourceId || grantMethod || reason || notes || revokedAt)
  ),
  expandedRowRender: (userRole) => {
    return (
      <TableCellDescriptions
        descriptions={getDescriptionsFromColumns(userRole, [
          columns.sourceIdColumn,
          columns.scheduleColumn,
          columns.grantMethodColumn,
          columns.reasonColumn,
          columns.notesColumn,
          columns.revokedAtColumn,
          columns.revokedByUserColumn,
        ])}
      />
    );
  },
};

export const commonTableConfig = { expandable };
