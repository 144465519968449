import AccessPointModel from './AccessPoint/model';
import * as accessPointTables from './AccessPoint/tables';
import * as accessPointRoleTables from './AccessPointRole/tables';
import BeaconModel from './Beacon/model';
import * as beaconTables from './Beacon/tables';
import CredentialModel from './Credential/model';
import * as credentialTables from './Credential/tables';
// import * as favoriteTables from './Favorite/tables';
import HubModel from './Hub/model';
import * as hubTables from './Hub/tables';
import OrganizationModel from './Organization/model';
import * as organizationTables from './Organization/tables';
import PinCodeModel from './PinCode/model';
import * as pinCodeTables from './PinCode/tables';
import PropertyModel from './Property/model';
import * as propertyTables from './Property/tables';
import RelayModel from './Relay/model';
import * as relayTables from './Relay/tables';
import RelayLogModel from './RelayLog/model';
import * as relayLogTables from './RelayLog/tables';
import RoleModel from './Role/model';
import * as roleTables from './Role/tables';
import SessionModel from './Session/model';
import * as sessionTables from './Session/tables';
import SmartLockModel from './SmartLock/model';
import * as smartLockTables from './SmartLock/tables';
import SmartLockLogModel from './SmartLockLog/model';
import * as smartLockLogTables from './SmartLockLog/tables';
import TicketModel from './Ticket/model';
import * as ticketTables from './Ticket/tables';
import UnitModel from './Unit/model';
import * as unitTables from './Unit/tables';
import UnitGroupModel from './UnitGroup/model';
import * as unitGroupTables from './UnitGroup/tables';
import UserModel from './User/model';
import * as userTables from './User/tables';
import UserRoleModel from './UserRole/model';
import * as userRoleTables from './UserRole/tables';
import VendorPasscode from './VendorPasscode/model';
import * as vendorPasscodeTables from './VendorPasscode/tables';

//
// The point of this file is to configure the models without creating circular dependencies.
// Many of the models use columns, labels, etc. from other models, especially in components.
//


// Main tables

AccessPointModel.tables.setMainTables([accessPointTables.AccessPointsMainTable]);
BeaconModel.tables.setMainTables([beaconTables.BeaconsMainTable]);
CredentialModel.tables.setMainTables([credentialTables.CredentialsMainTable]);
HubModel.tables.setMainTables([hubTables.HubsMainTable]);
OrganizationModel.tables.setMainTables([organizationTables.OrganizationsMainTable]);
PinCodeModel.tables.setMainTables([pinCodeTables.PinCodesMainTable]);
PropertyModel.tables.setMainTables([propertyTables.PropertiesMainTable]);
RelayModel.tables.setMainTables([relayTables.RelaysMainTable]);
RelayLogModel.tables.setMainTables([relayLogTables.RelayLogsMainTable]);
RoleModel.tables.setMainTables([roleTables.CustomRolesMainTable, roleTables.DefaultRolesMainTable]);
SessionModel.tables.setMainTables([sessionTables.SessionsMainTable]);
SmartLockModel.tables.setMainTables([smartLockTables.SmartLocksMainTable]);
SmartLockLogModel.tables.setMainTables([smartLockLogTables.SmartLockLogsMainTable]);
TicketModel.tables.setMainTables([ticketTables.TicketsMainTable]);
UnitModel.tables.setMainTables([unitTables.UnitsMainTable]);
UnitGroupModel.tables.setMainTables([unitGroupTables.UnitGroupsMainTable]);
UserModel.tables.setMainTables([userTables.UsersMainTable]);
UserRoleModel.tables.setMainTables([
  userRoleTables.GlobalUserRolesTable,
  userRoleTables.OrganizationUserRolesTable,
  userRoleTables.PropertyUserRolesTable,
  userRoleTables.UnitUserRolesTable,
  userRoleTables.AccessPointUserRolesTable,
  userRoleTables.SmartLockUserRolesTable,
]);
VendorPasscode.tables.setMainTables([vendorPasscodeTables.VendorPasscodesMainTable]);

// Tab tables

AccessPointModel.tables.tabTables = [
  relayTables.RelaysByAccessPointTable,
  beaconTables.BeaconsByAccessPointTable,
  userRoleTables.AccessPointUserRolesByAccessPointTable,
  // favoriteTables.FavoritesByAccessPointTable,
];

HubModel.tables.tabTables = [
  relayTables.RelaysByHubTable,
  accessPointTables.AccessPointsByHubTable,
];

OrganizationModel.tables.tabTables = [
  propertyTables.PropertiesByOrganizationTable,
  roleTables.RolesByOrganizationTable,
];

PropertyModel.tables.tabTables = [
  hubTables.HubsByPropertyTable,
  relayTables.RelaysByPropertyTable,
  accessPointTables.AccessPointsByPropertyTable,
  beaconTables.BeaconsByPropertyTable,
  unitTables.UnitsByPropertyTable,
  smartLockTables.SmartLocksByPropertyTable,
  credentialTables.FobsByPropertyTable,
  pinCodeTables.PinCodesByPropertyTable,
  vendorPasscodeTables.VendorPasscodesByPropertyTable,
];

RelayModel.tables.tabTables = [
  accessPointTables.AccessPointsByRelayTable,
  relayLogTables.RelayLogsByRelayTable,
];

SmartLockModel.tables.tabTables = [
  ticketTables.OpenTicketsBySmartLockTable,
  userRoleTables.UnitUserRolesBySmartLockTable,
  userRoleTables.SmartLockUserRolesBySmartLockTable,
  smartLockTables.RelatedSmartLocksTable,
  smartLockLogTables.SmartLockLogsBySmartLockTable,
];

UnitModel.tables.tabTables = [
  ticketTables.OpenTicketsByUnitTable,
  smartLockTables.SmartLocksByUnitTable,
  userRoleTables.UnitUserRolesByUnitTable,
  userRoleTables.SmartLockUserRolesByUnitTable,
  smartLockLogTables.SmartLockLogsByUnitTable,
];

UnitGroupModel.tables.tabTables = [
  unitGroupTables.ChildUnitGroupsTable,
  unitTables.UnitsByUnitGroupTable,
  accessPointRoleTables.AccessPointRolesByUnitGroupTable,
];

UserModel.tables.tabTables = [
  userRoleTables.OrganizationUserRolesByUserTable,
  userRoleTables.PropertyUserRolesByUserTable,
  userRoleTables.UnitUserRolesByUserTable,
  userRoleTables.GuestsByUserTable,
  credentialTables.CredentialsByUserTable,
  pinCodeTables.PinCodesByAssignedToUserTable,
  vendorPasscodeTables.VendorPasscodesByUserTable,
  sessionTables.SessionsByUserTable,
  relayLogTables.RelayLogsByUserTable,
  smartLockLogTables.SmartLockLogsByActingUserTable,
];

// Form Options

// tslint:disable ordered-imports
import './AccessPoint/form-options';
import './Beacon/form-options';
import './Credential/form-options';
import './Hub/form-options';
import './Organization/form-options';
import './Property/form-options';
import './Relay/form-options';
import './Role/form-options';
import './SmartLock/form-options';
import './Ticket/form-options';
import './Unit/form-options';
import './UnitGroup/form-options';
import './User/form-options';
import './UserRole/form-options';

// Routes

// tslint:disable ordered-imports
import './AccessPointRole/routes';
import './Credential/routes';
import './Organization/routes';
import './Hub/routes';
import './Property/routes';
import './Relay/routes';
import './RelayLog/routes';
import './Role/routes';
import './Session/routes';
import './SmartLock/routes';
import './SmartLockLog/routes';
import './Ticket/routes';
import './Unit/routes';
import './UnitGroup/routes';
import './User/routes';
import './UserRole/routes';
