import { filterQueriedRoles } from './filterQueriedRoles';
import { getScopeQueryFilters } from './getScopeQueryFilters';
import { getWarningFlags } from './getWarningFlags';
import { getWarnings } from './getWarnings';
import { IUseGrantableRolesArgs } from './typings';
import { useRolesQuery } from './useRolesQuery';
import { useScopeQueries } from './useScopeQueries';

export function useGrantableRoles(args: IUseGrantableRolesArgs) {
  const scopeQueryFilters = getScopeQueryFilters(args);

  const scopeQueriesResult = useScopeQueries(scopeQueryFilters);
  const rolesQueryResult = useRolesQuery(args, scopeQueriesResult);
  const grantableRoles = rolesQueryResult.roles;

  const warningFlags = getWarningFlags(args, scopeQueriesResult);
  const warnings = getWarnings({
    grantableRoles,
    warningFlags,
    organizations: scopeQueriesResult.organizations || [],
  });

  const filteredRoles = filterQueriedRoles(
    grantableRoles,
    args.permissionScope,
    warningFlags,
  );

  return {
    warnings,
    roles: filteredRoles,
    loading: scopeQueriesResult.loading || rolesQueryResult.loading,
  };
}
