import { FormikBag, FormikErrors } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';
import validator from 'validator';

import { apiClient } from '../../../../graphql/api/apiClient';
import {
  MessageMethod,
  sendInvitationDocument,
  sendInvitationMutation,
  sendInvitationMutationVariables,
} from '../../../../graphql/api/generated';
import { UserUniqueLabelFragment } from '../../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../../utils';

export interface ISendInvitationFormProps {
  userRole: {
    userRoleId: string;
    assignedToUser?: UserUniqueLabelFragment | null;
  };
  steps: React.ReactNode;
  disabled?: boolean;
}

export interface ISendInvitationFormValues {
  messageMethod: MessageMethod;
  phoneNumber?: string | null;
  email?: string | null;
  invitationSent: boolean;
}

export function mapPropsToValues(props: ISendInvitationFormProps): ISendInvitationFormValues {
  const { assignedToUser } = props.userRole;
  const phoneNumber = assignedToUser?.phoneNumber;
  const email = assignedToUser?.email;

  return {
    phoneNumber,
    email,
    messageMethod: !phoneNumber && email ? MessageMethod.EMAIL : MessageMethod.SMS,
    invitationSent: false,
  };
}

export function validate(values: ISendInvitationFormValues) {
  const errors: FormikErrors<ISendInvitationFormValues> = {};

  if (values.messageMethod === MessageMethod.SMS) {
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Please provide a phone number';
    } else if (!(parsePhoneNumberFromString(values.phoneNumber || '', 'US')?.isValid())) {
      errors.phoneNumber = 'Please provide a valid phone number';
    }
  }

  if (values.messageMethod === MessageMethod.EMAIL) {
    if (!values.email) {
      errors.email = 'Please provide an email address';
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Please provide a valid email';
    }
  }

  return errors;
}

export async function handleSubmit(
  values: ISendInvitationFormValues,
  formikBag: FormikBag<ISendInvitationFormProps, ISendInvitationFormValues>
) {
  try {
    const { userRole } = formikBag.props;

    await apiClient.mutate<sendInvitationMutation, sendInvitationMutationVariables>({
      mutation: sendInvitationDocument,
      variables: {
        input: {
          messageMethod: values.messageMethod,
          userRoleId: userRole.userRoleId,
          phoneNumber: values.phoneNumber,
          email: values.email,
        },
      },
    });

    formikBag.setFieldValue('invitationSent', true);
  } catch (error) {
    displayErrorMessage(error);
    formikBag.setFieldValue('invitationSent', false);
  }

  formikBag.setSubmitting(false);
}
