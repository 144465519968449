import { FormikBag, FormikErrors, FormikProps } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import pluralize from 'pluralize';
import validator from 'validator';

import { apiClient } from '../../../graphql/api/apiClient';
import {
  findOrCreateUser,
  grantGlobalUserRole,
  grantScopedUserRoles,
} from '../../../graphql/api/operations';
import {
  GrantableRoleFragment,
  PermissionKey_enum,
  PermissionScope_enum,
} from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { IModel } from '../../../models/typings';
import history from '../../../routes/history';
import { authentication } from '../../../stores';
import { displayErrorMessage, parsePhoneNumber } from '../../../utils';
import { ISharedFormValues, validateSharedFormValues } from '../shared-form/formik';

import type { PresetFormValues } from './usePresetFormValues';

const MAX_USER_ROLES_PER_REQUEST = 25;

export interface IRegisteredUserFormProps extends PresetFormValues {
  loading: boolean;
  newUser: boolean;
  UserModel: IModel;
  UserRoleModel: IModel;
  presetRole?: GrantableRoleFragment | null;
}

export interface IRegisteredUserFormValues extends ISharedFormValues {
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  sendSms: boolean;

  vacantUnitsFilter: {
    applied: boolean;
    disabled: boolean;
  };
}

export interface IRegisteredUserFormMergedProps extends
  IRegisteredUserFormProps, FormikProps<IRegisteredUserFormValues> {}

export function mapPropsToValues(props: IRegisteredUserFormProps): IRegisteredUserFormValues {
  const grantVacantUnitsOnly = authentication.currentPermissions.some(p => (
    p.key === PermissionKey_enum.UserRole_GrantVacantUnitsOnly
  ));

  return {
    userId: props.presetUserId,
    permissionScope: props.presetPermissionScope,
    vacantUnitsFilter: {
      applied: grantVacantUnitsOnly || false,
      disabled: grantVacantUnitsOnly,
    },
    scopedIds: props.presetScopedIds || [],
    role: props.presetRole,
    activatesAt: new Date(),
    reason: props.presetReason,
    sendSms: false,
    acknowledged: false,
  };
}

export function validate(values: IRegisteredUserFormValues, props: IRegisteredUserFormProps) {
  const errors: FormikErrors<IRegisteredUserFormValues> = {};

  if (values.permissionScope && values.scopedIds.length > MAX_USER_ROLES_PER_REQUEST) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = (
      `A maximum of ${MAX_USER_ROLES_PER_REQUEST} ${pluralize(values.permissionScope.toLowerCase())} may be selected`
    );
  }

  if (!values.acknowledged) {
    return errors;
  }

  if (props.newUser) {
    if (!values.firstName) {
      errors.firstName = `Please provide the user's first name`;
    }

    if (!values.lastName) {
      errors.lastName = `Please provide the user's last name`;
    }

    if (!values.phoneNumber && !values.email) {
      errors.phoneNumber = errors.email = `Please provide either a phone number or an email address`;
    }

    if (values.email && !validator.isEmail(values.email)) {
      errors.email = 'Please provide a valid email address';
    }

    if (
      values.phoneNumber &&
      !(parsePhoneNumberFromString(values.phoneNumber || '', 'US')?.isValid())
    ) {
      errors.phoneNumber = 'Please provide a valid phone number';
    }
  }

  if (!props.newUser && !values.userId) {
    errors.userId = 'Please select a user';
  }

  if (!values.permissionScope) {
    errors.permissionScope = 'Please select scope of access';
  }

  if (
    values.permissionScope &&
    values.permissionScope !== PermissionScope_enum.GLOBAL &&
    !values.scopedIds.length
  ) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = `Please select at least one ${values.permissionScope.toLowerCase()}`;
  }

  return { ...errors, ...validateSharedFormValues(values) };
}

export async function handleSubmit(
  values: IRegisteredUserFormValues,
  formikBag: FormikBag<IRegisteredUserFormProps, IRegisteredUserFormValues>
) {
  try {
    let { userId } = values;

    if (formikBag.props.newUser) {
      const { firstName, lastName, email } = values;
      const phoneNumber = parsePhoneNumber(values.phoneNumber);

      if (!firstName || !lastName) {
        throw new Error('Missing necessary user information');
      }

      const user = await findOrCreateUser({
        input: { firstName, lastName, phoneNumber, email },
      });

      userId = user?.userId;
    }

    const { scopedIds, permissionScope, role, schedule } = values;

    if (!userId) {
      throw new Error('Missing user information');
    }

    if (!role) {
      throw new Error('Missing role information');
    }

    if (permissionScope === PermissionScope_enum.GLOBAL) {
      await grantGlobalUserRole({
        input: {
          roleId: role.roleId,
          assignedToUserId: userId,
          sendSms: values.sendSms,
        },
      });
    } else {
      await grantScopedUserRoles({
        input: {
          scopedIds,
          schedule,
          roleId: role.roleId,
          assignedToUserId: userId,
          activatesAt: values.activatesAt.toISOString(),
          expiresAt: values.expiresAt?.toISOString(),
          reason: values.reason,
          notes: values.notes,
          sendSms: values.sendSms,
        },
      });
    }

    // Ensure that cached tables refetch data when redirected
    await hasuraClient.resetStore();
    await apiClient.resetStore();

    history.push(`/users/details/${userId}`);
  } catch (error) {
    formikBag.setSubmitting(false);
    displayErrorMessage(error);
  }
}
