import { Card } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  UserRoleDetailsFragment,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { SmartLockModel } from '../../SmartLock/model';
import { UnitModel } from '../../Unit/model';

import { getInvitationInfo, SendSgtInvitationForm, SendVendorPassInvitationForm } from './SendInvitationForm';
import SmartLockPinCodeTable from './SmartLockPinCodeTable';

interface IUserRoleDetailsFooterProps {
  userRole: UserRoleDetailsFragment;
}

export const UserRoleDetailsFooter: React.FC<IUserRoleDetailsFooterProps> = ({ userRole }) => {
  const isValidUserRole = (
    userRole.status === UserRoleStatus_enum.ACTIVE ||
    userRole.status === UserRoleStatus_enum.PENDING
  );

  const { propertyFeatureFlags, hasPropertyPinCode } = getInvitationInfo(userRole);

  const smartLockUserRoles = userRole.role.permissionScope === PermissionScope_enum.SMART_LOCK
    ? [userRole]
    : userRole.childUserRoles.filter(u => !!u.scopedSmartLock);

  const showSmartLockPinCodes = (
    !!smartLockUserRoles.length &&
    UnitModel.permissions.canRead() &&
    SmartLockModel.permissions.canRead()
  );

  const canSendInvitation = (
    isValidUserRole &&
    authentication.hasPermission(PermissionKey_enum.User_SendMessage)
  );

  const canSendSgtInvitation = (
    canSendInvitation &&
    userRole.role.key === RoleKey_enum.GUEST_PROPERTY_KEY &&
    (propertyFeatureFlags.SMART_SIGNS || hasPropertyPinCode || showSmartLockPinCodes)
  );

  const canSendVendorInvitation = (
    canSendInvitation &&
    userRole.role.key === RoleKey_enum.VENDOR_PASS &&
    authentication.canGrantRole(RoleKey_enum.VENDOR_PASS)
  );

  return (
    <>
      {showSmartLockPinCodes && <SmartLockPinCodeTable userRoles={smartLockUserRoles} />}
      {(canSendSgtInvitation || canSendVendorInvitation) && (
        <Card
          title='Send Invitation'
          bordered={false}
          style={{ marginBottom: 20 }}
        >
          {canSendSgtInvitation && <SendSgtInvitationForm userRole={userRole} />}
          {canSendVendorInvitation && <SendVendorPassInvitationForm userRole={userRole} />}
        </Card>
      )}
    </>
  )
};
